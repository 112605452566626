/*********** TODO ********************************
 * Change the javascript to be compatible with ReactJS...
 * Only allow "N" keystroke to trigger the "Next" button when the question has been successfully answered...
 *
 *************************************************/

import { FunctionComponent, useEffect, useRef, useState } from "react";
import Div100vh from "react-div-100vh";
import Airport from "../interfaces/Airport";

interface MultipleChoiceProps {
  airports: Airport[];
  codeOrCity: boolean;
  onClickNext: (numberOfAirports: number) => void;
}

enum Answer {
  CORRECT,
  INCORRECT,
  UNANSWERED,
}

const MultipleChoice: FunctionComponent<MultipleChoiceProps> = ({
  airports,
  codeOrCity,
  onClickNext,
}) => {
  const currentNextButton = useRef<HTMLButtonElement>(null);
  const [airportsState, setAirportsState] = useState<Airport[]>(
    airports.map((airport) => ({ ...airport }))
  );
  const [answerIndex, setAnswerIndex] = useState<number>(
    Math.floor(Math.random() * airportsState.length)
  );
  useEffect(() => {
    document.body.onkeyup = (e) => {
      console.log(e.key, questionIsAnswered(), currentNextButton.current);
      if (
        e.key.match(/N/i) &&
        questionIsAnswered() &&
        currentNextButton.current
      ) {
        currentNextButton.current.click();
      } else if (e.key.match(/[1-5]/)) {
        let element = document.getElementById(`answer${e.key.toUpperCase()}`);
        element?.click();
      }
    };
  }, []);

  useEffect(() => {
    setAirportsState(airports.map((airport) => ({ ...airport })));
    setAnswerIndex(Math.floor(Math.random() * airportsState.length));
  }, [airports]);

  const questionIsAnswered = () =>
    airportsState.filter((airport) => airport.answer !== undefined).length > 0;

  const disableWrongChoices = (index: number | undefined = undefined) => {
    let newAirportsState = airportsState.map((airport, airportIndex) => {
      let newAirport = { ...airport };

      if (index === undefined && airportIndex !== answerIndex) {
        newAirport.disabled = true;
      } else if (index === airportIndex) {
        newAirport.disabled = true;
      }

      return newAirport;
    });

    setAirportsState(newAirportsState);
  };

  const onClickAnswer = (index: number) => {
    let newAirportsState = airportsState.map((airport, i) => ({ ...airport }));
    let airport = newAirportsState[index];

    if (airportsState[answerIndex].airportCode === airport.airportCode) {
      // Correct answer
      airport.answer = Answer.CORRECT;
      disableWrongChoices();
    } else {
      // Wrong Answer
      airport.disabled = true;
    }

    setAirportsState(newAirportsState);
  };

  return (
    <Div100vh>
      <h1 className="text-5xl pt-10 h-1/4 text-center">
        {codeOrCity
          ? airportsState[answerIndex].airportCode
          : `${airportsState[answerIndex].cityName}, ${airportsState[answerIndex].region}`}
      </h1>
      <div className="h-3/4 grid grid-cols-1 px-4">
        {airportsState.map((airport, index) => {
          return (
            <label
              key={index}
              className={`before-["1"] transition-all grid items-center text-center w-full
                text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xl mb-2 focus:outline-none
                bg-blue-700 
                ${
                  !questionIsAnswered() &&
                  !airport.disabled &&
                  "hover:bg-blue-800 hover:cursor-pointer"
                }
                ${
                  (airport.disabled ||
                    (questionIsAnswered() &&
                      airport.answer !== Answer.CORRECT)) &&
                  "bg-gray-400 line-through"
                }
                ${
                  questionIsAnswered() &&
                  airport.answer !== Answer.CORRECT &&
                  "duration-500 scale-0"
                }
                ${airport.answer === Answer.CORRECT && "bg-green-500 font-bold"}
                `}
              id={`answer${index + 1}`}
              onClick={() => {
                if (
                  !airport.disabled &&
                  airport.answer === undefined /*&&
                  !questionIsAnswered()*/
                ) {
                  onClickAnswer(index);
                }
              }}
            >
              <div
                className={`w-[10%] ml-4 my-auto flex items-stretch
              text-red font-medium rounded-tr-lg rounded-br-lg text-xl
              ${(airport.disabled || questionIsAnswered()) && "hidden"}
              `}
                style={{ gridArea: "1 / 1 / 2 / 2" }}
              >
                <span className="self-center text-black font-bold flex mx-auto bg-white w-6 h-6 rounded-full">
                  <span className="self-center mx-auto">{index + 1}</span>
                </span>
              </div>
              <input
                type="radio"
                name="answer"
                value={airport.airportCode}
                disabled={airport.disabled}
                // className={`${airport.disabled && "invisible"}`}
                className="hidden"
              />
              <span style={{ gridArea: "1 / 1 / 2 / 2" }}>
                {" "}
                {codeOrCity
                  ? `${airport.cityName}, ${airport.region}`
                  : airport.airportCode}
              </span>
              <button
                ref={
                  airport.answer === Answer.CORRECT
                    ? currentNextButton
                    : undefined
                }
                onClick={() => onClickNext(5)}
                id="answerN"
                className={`${
                  airport.answer === Answer.CORRECT || "hidden"
                } h-full w-[20%] ml-[80%] flex items-stretch
              text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-tr-lg rounded-br-lg text-xl focus:outline-none
              bg-blue-700 hover:bg-blue-800 hover:cursor-pointer
              `}
                style={{ gridArea: "1 / 1 / 2 / 2" }}
              >
                <div className="self-center flex mx-auto">
                  <div className="self-center">
                    <u>N</u>ext
                  </div>
                  <div className="self-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="flex-none block w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </label>
          );
        })}
      </div>
    </Div100vh>
  );
};

export default MultipleChoice;
