const airports = [
  {
    latitude: 57.0927,
    longitude: 9.84916,
    airportCode: "AAL",
    airportName: "Aalborg Airport",
    cityCode: "AAL",
    cityName: "Aalborg",
    countryCode: "DK",
    region: "Denmark",
  },
  {
    latitude: 40.6522,
    longitude: -75.44083,
    airportCode: "ABE",
    airportName: "Lehigh Valley International",
    cityCode: "ABE",
    cityName: "Allentown",
    countryCode: "US",
    region: "PA",
  },
  {
    latitude: 5.2613,
    longitude: -3.92638,
    airportCode: "ABJ",
    airportName: "Felix Houphouet Boigny",
    cityCode: "ABJ",
    cityName: "Abidjan",
    countryCode: "CI",
    region: "Cote d Ivoire",
  },
  {
    latitude: 35.0405,
    longitude: -106.60944,
    airportCode: "ABQ",
    airportName: "Albuquerque Intl",
    cityCode: "ABQ",
    cityName: "Albuquerque",
    countryCode: "US",
    region: "NM",
  },
  {
    latitude: 45.4536,
    longitude: -98.42555,
    airportCode: "ABR",
    airportName: "Aberdeen Municipal Airport",
    cityCode: "ABR",
    cityName: "Aberdeen",
    countryCode: "US",
    region: "SD",
  },
  {
    latitude: 9.0066,
    longitude: 7.26305,
    airportCode: "ABV",
    airportName: "Nnamdi Azikiwe International Airport",
    cityCode: "ABV",
    cityName: "Abuja",
    countryCode: "NG",
    region: "Nigeria",
  },
  {
    latitude: 31.5355,
    longitude: -84.19444,
    airportCode: "ABY",
    airportName: "Dougherty County",
    cityCode: "ABY",
    cityName: "Albany",
    countryCode: "US",
    region: "GA",
  },
  {
    latitude: 57.2041,
    longitude: -2.20027,
    airportCode: "ABZ",
    airportName: "Dyce",
    cityCode: "ABZ",
    cityName: "Aberdeen",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 5.6052,
    longitude: -0.16666,
    airportCode: "ACC",
    airportName: "Kotoka",
    cityCode: "ACC",
    cityName: "Accra",
    countryCode: "GH",
    region: "Ghana",
  },
  {
    latitude: 28.9455,
    longitude: -13.60527,
    airportCode: "ACE",
    airportName: "Lanzarote",
    cityCode: "ACE",
    cityName: "Lanzarote",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 41.253,
    longitude: -70.06027,
    airportCode: "ACK",
    airportName: "Nantucket Memorial",
    cityCode: "ACK",
    cityName: "Nantucket",
    countryCode: "US",
    region: "MA",
  },
  {
    latitude: 8.9777,
    longitude: 38.79944,
    airportCode: "ADD",
    airportName: "Bole International",
    cityCode: "ADD",
    cityName: "Addis Ababa",
    countryCode: "ET",
    region: "Ethiopia",
  },
  {
    latitude: -34.945,
    longitude: 138.53055,
    airportCode: "ADL",
    airportName: "Adelaide",
    cityCode: "ADL",
    cityName: "Adelaide",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 12.5836,
    longitude: -81.71111,
    airportCode: "ADZ",
    airportName: "Gustavo Rojas Pinilla Intl Arpt",
    cityCode: "ADZ",
    cityName: "San Andr�s Island",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 62.5602,
    longitude: 6.11027,
    airportCode: "AES",
    airportName: "Alesund Arpt Vigra",
    cityCode: "AES",
    cityName: "Alesund",
    countryCode: "NO",
    region: "Norway",
  },
  {
    latitude: 31.3275,
    longitude: -92.54972,
    airportCode: "AEX",
    airportName: "Alexandria Intl Airport",
    cityCode: "AEX",
    cityName: "Alexandria",
    countryCode: "US",
    region: "LA",
  },
  {
    latitude: 36.675,
    longitude: -4.49916,
    airportCode: "AGP",
    airportName: "Pablo Ruiz Picasso",
    cityCode: "AGP",
    cityName: "Malaga",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 33.37,
    longitude: -81.96444,
    airportCode: "AGS",
    airportName: "Bush Field",
    cityCode: "AGS",
    cityName: "Augusta",
    countryCode: "US",
    region: "GA",
  },
  {
    latitude: 41.9238,
    longitude: 8.8025,
    airportCode: "AJA",
    airportName: "Campo Dell Oro",
    cityCode: "AJA",
    cityName: "Ajaccio",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: -10.9838,
    longitude: -37.07027,
    airportCode: "AJU",
    airportName: "Santa Maria",
    cityCode: "AJU",
    cityName: "Aracaju",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 42.7483,
    longitude: -73.80166,
    airportCode: "ALB",
    airportName: "Albany International",
    cityCode: "ALB",
    cityName: "Albany",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 38.2822,
    longitude: -0.55805,
    airportCode: "ALC",
    airportName: "Alicante El Altet",
    cityCode: "ALC",
    cityName: "Alicante",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 31.7225,
    longitude: 35.99333,
    airportCode: "AMM",
    airportName: "Queen Alia Intl Arpt",
    cityCode: "AMM",
    cityName: "Amman",
    countryCode: "JO",
    region: "Jordan",
  },
  {
    latitude: 52.308,
    longitude: 4.76416,
    airportCode: "AMS",
    airportName: "Schiphol",
    cityCode: "AMS",
    cityName: "Amsterdam",
    countryCode: "NL",
    region: "Netherlands",
  },
  {
    latitude: 61.1744,
    longitude: -149.99638,
    airportCode: "ANC",
    airportName: "Ted Stevens Anchorage International Airp",
    cityCode: "ANC",
    cityName: "Anchorage",
    countryCode: "US",
    region: "AK",
  },
  {
    latitude: 17.1366,
    longitude: -61.79277,
    airportCode: "ANU",
    airportName: "V. C. Bird Intl",
    cityCode: "ANU",
    cityName: "Antigua",
    countryCode: "AG",
    region: "Antigua",
  },
  {
    latitude: 40.7347,
    longitude: 140.69083,
    airportCode: "AOJ",
    airportName: "Aomori",
    cityCode: "AOJ",
    cityName: "Aomori",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 45.078,
    longitude: -83.56027,
    airportCode: "APN",
    airportName: "Alpena County Regional",
    cityCode: "APN",
    cityName: "Alpena",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: -16.3408,
    longitude: -71.5725,
    airportCode: "AQP",
    airportName: "Rodr�guez Ball�n Intl Arpt",
    cityCode: "AQP",
    cityName: "Arequipa",
    countryCode: "PE",
    region: "Peru",
  },
  {
    latitude: 59.6519,
    longitude: 17.91861,
    airportCode: "ARN",
    airportName: "Arlanda Airport",
    cityCode: "STO",
    cityName: "Stockholm",
    countryCode: "SE",
    region: "Sweden",
  },
  {
    latitude: 37.9363,
    longitude: 23.94444,
    airportCode: "ATH",
    airportName: "Eleftherios Venizelos",
    cityCode: "ATH",
    cityName: "Athens",
    countryCode: "GR",
    region: "Greece",
  },
  {
    latitude: 33.6366,
    longitude: -84.42805,
    airportCode: "ATL",
    airportName: "Hartsfield-Jackson Atlanta Intl",
    cityCode: "ATL",
    cityName: "Atlanta",
    countryCode: "US",
    region: "GA",
  },
  {
    latitude: 44.2666,
    longitude: -88.51666,
    airportCode: "ATW",
    airportName: "Appleton Intl Arpt",
    cityCode: "ATW",
    cityName: "Appleton",
    countryCode: "US",
    region: "WI",
  },
  {
    latitude: 12.5013,
    longitude: -70.01527,
    airportCode: "AUA",
    airportName: "Reina Beatrix",
    cityCode: "AUA",
    cityName: "Aruba",
    countryCode: "AW",
    region: "Aruba",
  },
  {
    latitude: 24.433,
    longitude: 54.65111,
    airportCode: "AUH",
    airportName: "Abu Dhabi International",
    cityCode: "AUH",
    cityName: "Abu Dhabi",
    countryCode: "AE",
    region: "United Arab Emirates",
  },
  {
    latitude: 30.1944,
    longitude: -97.67,
    airportCode: "AUS",
    airportName: "Austin-Bergstrom International",
    cityCode: "AUS",
    cityName: "Austin",
    countryCode: "US",
    region: "TX",
  },
  {
    latitude: 35.4361,
    longitude: -82.54194,
    airportCode: "AVL",
    airportName: "Asheville Regl",
    cityCode: "AVL",
    cityName: "Asheville",
    countryCode: "US",
    region: "NC",
  },
  {
    latitude: 18.2047,
    longitude: -63.055,
    airportCode: "AXA",
    airportName: "Clayton J. Lloyd Intl Arpt",
    cityCode: "AXA",
    cityName: "Anguilla",
    countryCode: "AI",
    region: "British West Indies",
  },
  {
    latitude: 42.235,
    longitude: -85.55194,
    airportCode: "AZO",
    airportName: "Kalamazoo/Battle Creek Intl",
    cityCode: "AZO",
    cityName: "Kalamazoo",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 26.2708,
    longitude: 50.63361,
    airportCode: "BAH",
    airportName: "Bahrain International",
    cityCode: "BAH",
    cityName: "Bahrain",
    countryCode: "BH",
    region: "Bahrain",
  },
  {
    latitude: 10.8897,
    longitude: -74.78083,
    airportCode: "BAQ",
    airportName: "Ernesto Cortissoz Intl Arpt",
    cityCode: "BAQ",
    cityName: "Barranquilla",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 41.2969,
    longitude: 2.07833,
    airportCode: "BCN",
    airportName: "Barcelona-El Prat",
    cityCode: "BCN",
    cityName: "Barcelona",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 32.3641,
    longitude: -64.67861,
    airportCode: "BDA",
    airportName: "Bermuda International Airport",
    cityCode: "BDA",
    cityName: "Bermuda",
    countryCode: "BM",
    region: "Bermuda",
  },
  {
    latitude: 41.9388,
    longitude: -72.68333,
    airportCode: "BDL",
    airportName: "Bradley Intl",
    cityCode: "BDL",
    cityName: "Hartford/Springfield",
    countryCode: "US",
    region: "CT",
  },
  {
    latitude: 40.6605,
    longitude: 17.94805,
    airportCode: "BDS",
    airportName: "Papola Casale",
    cityCode: "BDS",
    cityName: "Brindisi",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 44.8183,
    longitude: 20.30916,
    airportCode: "BEG",
    airportName: "Belgrade Nikola Tesla Arpt",
    cityCode: "BEG",
    cityName: "Belgrade",
    countryCode: "RS",
    region: "Serbia",
  },
  {
    latitude: -1.3791,
    longitude: -48.47638,
    airportCode: "BEL",
    airportName: "Val De Cans Intl",
    cityCode: "BEL",
    cityName: "Belem",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 52.3666,
    longitude: 13.50333,
    airportCode: "BER",
    airportName: "Berlin Brandenburg Arpt",
    cityCode: "BER",
    cityName: "Berlin",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: 48.4472,
    longitude: -4.42166,
    airportCode: "BES",
    airportName: "Brest Lesquin",
    cityCode: "BES",
    cityName: "Brest",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 7.1263,
    longitude: -73.18472,
    airportCode: "BGA",
    airportName: "Palo Negro",
    cityCode: "BGA",
    cityName: "Bucaramanga",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 4.3977,
    longitude: 18.52,
    airportCode: "BGF",
    airportName: "Bangui",
    cityCode: "BGF",
    cityName: "Bangui",
    countryCode: "CF",
    region: "Central African Republic",
  },
  {
    latitude: 42.2086,
    longitude: -75.97972,
    airportCode: "BGM",
    airportName: "Greater Binghamton",
    cityCode: "BGM",
    cityName: "Binghamton",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 60.2933,
    longitude: 5.21805,
    airportCode: "BGO",
    airportName: "Bergen Airport, Flesland",
    cityCode: "BGO",
    cityName: "Bergen",
    countryCode: "NO",
    region: "Norway",
  },
  {
    latitude: 44.8075,
    longitude: -68.82805,
    airportCode: "BGR",
    airportName: "Bangor International Airport",
    cityCode: "BGR",
    cityName: "Bangor",
    countryCode: "US",
    region: "ME",
  },
  {
    latitude: 54.618,
    longitude: -5.8725,
    airportCode: "BHD",
    airportName: "George Best Belfast City",
    cityCode: "BHD",
    cityName: "Belfast City",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 33.563,
    longitude: -86.75361,
    airportCode: "BHM",
    airportName: "Birmingham Intl",
    cityCode: "BHM",
    cityName: "Birmingham",
    countryCode: "US",
    region: "AL",
  },
  {
    latitude: 52.4536,
    longitude: -1.74777,
    airportCode: "BHX",
    airportName: "Birmingham International Airport",
    cityCode: "BHX",
    cityName: "Birmingham",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 42.55,
    longitude: 9.48472,
    airportCode: "BIA",
    airportName: "Poretta",
    cityCode: "BIA",
    cityName: "Bastia",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 45.8077,
    longitude: -108.54277,
    airportCode: "BIL",
    airportName: "Billings",
    cityCode: "BIL",
    cityName: "Billings",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: 43.3011,
    longitude: -2.91055,
    airportCode: "BIO",
    airportName: "Sondica",
    cityCode: "BIO",
    cityName: "Bilbao",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 46.7722,
    longitude: -100.74583,
    airportCode: "BIS",
    airportName: "Bismarck",
    cityCode: "BIS",
    cityName: "Bismarck",
    countryCode: "US",
    region: "ND",
  },
  {
    latitude: 47.5097,
    longitude: -94.93305,
    airportCode: "BJI",
    airportName: "Bemidji",
    cityCode: "BJI",
    cityName: "Bemidji",
    countryCode: "US",
    region: "MN",
  },
  {
    latitude: -3.3238,
    longitude: 29.31861,
    airportCode: "BJM",
    airportName: "International",
    cityCode: "BJM",
    cityName: "Bujumbura",
    countryCode: "BI",
    region: "Burundi",
  },
  {
    latitude: 13.6858,
    longitude: 100.74888,
    airportCode: "BKK",
    airportName: "Suvarnabhumi International",
    cityCode: "BKK",
    cityName: "Bangkok",
    countryCode: "TH",
    region: "Thailand",
  },
  {
    latitude: 12.5336,
    longitude: -7.95,
    airportCode: "BKO",
    airportName: "Bamako",
    cityCode: "BKO",
    cityName: "Bamako",
    countryCode: "ML",
    region: "Mali",
  },
  {
    latitude: 55.7402,
    longitude: 9.15166,
    airportCode: "BLL",
    airportName: "Billund",
    cityCode: "BLL",
    cityName: "Billund",
    countryCode: "DK",
    region: "Denmark",
  },
  {
    latitude: 44.5308,
    longitude: 11.29694,
    airportCode: "BLQ",
    airportName: "Guglielmo Marconi Arpt",
    cityCode: "BLQ",
    cityName: "Bologna",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 13.1988,
    longitude: 77.70555,
    airportCode: "BLR",
    airportName: "Bangalore",
    cityCode: "BLR",
    cityName: "Bangalore",
    countryCode: "IN",
    region: "India",
  },
  {
    latitude: 40.4772,
    longitude: -88.91583,
    airportCode: "BMI",
    airportName: "Bloomington Normal Arpt",
    cityCode: "BMI",
    cityName: "Bloomington",
    countryCode: "US",
    region: "IL",
  },
  {
    latitude: 36.1244,
    longitude: -86.67833,
    airportCode: "BNA",
    airportName: "Nashville Intl",
    cityCode: "BNA",
    cityName: "Nashville",
    countryCode: "US",
    region: "TN",
  },
  {
    latitude: -27.3841,
    longitude: 153.1175,
    airportCode: "BNE",
    airportName: "Brisbane",
    cityCode: "BNE",
    cityName: "Brisbane",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 44.8286,
    longitude: -0.71527,
    airportCode: "BOD",
    airportName: "Bordeaux-Merignac Arpt",
    cityCode: "BOD",
    cityName: "Bordeaux",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 4.7016,
    longitude: -74.14694,
    airportCode: "BOG",
    airportName: "Eldorado International",
    cityCode: "BOG",
    cityName: "Bogota",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 43.5644,
    longitude: -116.22277,
    airportCode: "BOI",
    airportName: "Boise Air Terminal/Gowen Field",
    cityCode: "BOI",
    cityName: "Boise",
    countryCode: "US",
    region: "ID",
  },
  {
    latitude: 19.0886,
    longitude: 72.86805,
    airportCode: "BOM",
    airportName: "Chhatrapati Shivaji Intl",
    cityCode: "BOM",
    cityName: "Mumbai",
    countryCode: "IN",
    region: "India",
  },
  {
    latitude: 12.1311,
    longitude: -68.26861,
    airportCode: "BON",
    airportName: "Flamingo International",
    cityCode: "BON",
    cityName: "Bonaire",
    countryCode: "BQ",
    region: "Bonaire",
  },
  {
    latitude: 42.3644,
    longitude: -71.00527,
    airportCode: "BOS",
    airportName: "Logan Intl",
    cityCode: "BOS",
    cityName: "Boston",
    countryCode: "US",
    region: "MA",
  },
  {
    latitude: 31.2588,
    longitude: -81.46638,
    airportCode: "BQK",
    airportName: "Glynco Jetport",
    cityCode: "BQK",
    cityName: "Brunswick-Golden Isles",
    countryCode: "US",
    region: "GA",
  },
  {
    latitude: 46.4033,
    longitude: -94.12972,
    airportCode: "BRD",
    airportName: "Brainerd Lakes Regional",
    cityCode: "BRD",
    cityName: "Brainerd",
    countryCode: "US",
    region: "MN",
  },
  {
    latitude: 53.0475,
    longitude: 8.78666,
    airportCode: "BRE",
    airportName: "Bremen",
    cityCode: "BRE",
    cityName: "Bremen",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: 41.138,
    longitude: 16.765,
    airportCode: "BRI",
    airportName: "Palese",
    cityCode: "BRI",
    cityName: "Bari",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 51.3825,
    longitude: -2.71888,
    airportCode: "BRS",
    airportName: "Bristol",
    cityCode: "BRS",
    cityName: "Bristol",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 50.9013,
    longitude: 4.48444,
    airportCode: "BRU",
    airportName: "Brussels Airport",
    cityCode: "BRU",
    cityName: "Brussels",
    countryCode: "BE",
    region: "Belgium",
  },
  {
    latitude: -15.8711,
    longitude: -47.91861,
    airportCode: "BSB",
    airportName: "Presidente Juscelino Kubitschek",
    cityCode: "BSB",
    cityName: "Brasilia",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 47.6011,
    longitude: 7.52166,
    airportCode: "BSL",
    airportName: "Euroairport Basel Mulhouse Freiburg",
    cityCode: "BSL",
    cityName: "Basel",
    countryCode: "CH",
    region: "Switzerland",
  },
  {
    latitude: 45.9547,
    longitude: -112.4975,
    airportCode: "BTM",
    airportName: "Bert Mooney Airport",
    cityCode: "BTM",
    cityName: "Butte",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: 30.533,
    longitude: -91.14972,
    airportCode: "BTR",
    airportName: "Ryan",
    cityCode: "BTR",
    cityName: "Baton Rouge",
    countryCode: "US",
    region: "LA",
  },
  {
    latitude: 44.4719,
    longitude: -73.15333,
    airportCode: "BTV",
    airportName: "Burlington Intl",
    cityCode: "BTV",
    cityName: "Burlington",
    countryCode: "US",
    region: "VT",
  },
  {
    latitude: 47.4369,
    longitude: 19.25555,
    airportCode: "BUD",
    airportName: "Ferihegy",
    cityCode: "BUD",
    cityName: "Budapest",
    countryCode: "HU",
    region: "Hungary",
  },
  {
    latitude: 42.9405,
    longitude: -78.73222,
    airportCode: "BUF",
    airportName: "Buffalo Niagara International",
    cityCode: "BUF",
    cityName: "Buffalo",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 34.2005,
    longitude: -118.35861,
    airportCode: "BUR",
    airportName: "Bob Hope Airport",
    cityCode: "BUR",
    cityName: "Burbank",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 39.1752,
    longitude: -76.66833,
    airportCode: "BWI",
    airportName: "Baltimore/Washington Intl Thurgood Marshall Airport",
    cityCode: "BWI",
    cityName: "Baltimore",
    countryCode: "US",
    region: "MD",
  },
  {
    latitude: 17.5391,
    longitude: -88.30833,
    airportCode: "BZE",
    airportName: "Philip S W Goldson Intl",
    cityCode: "BZE",
    cityName: "Belize City",
    countryCode: "BZ",
    region: "Belize",
  },
  {
    latitude: 45.7775,
    longitude: -111.15305,
    airportCode: "BZN",
    airportName: "Gallatin",
    cityCode: "BZN",
    cityName: "Bozeman/Big Sky",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: -4.2516,
    longitude: 15.25305,
    airportCode: "BZV",
    airportName: "Maya Maya",
    cityCode: "BZV",
    cityName: "Brazzaville",
    countryCode: "CG",
    region: "Congo",
  },
  {
    latitude: 33.9388,
    longitude: -81.11944,
    airportCode: "CAE",
    airportName: "Metropolitan Airport",
    cityCode: "CAE",
    cityName: "Columbia",
    countryCode: "US",
    region: "SC",
  },
  {
    latitude: 39.2513,
    longitude: 9.05777,
    airportCode: "CAG",
    airportName: "Cagliari-Elmas Intl Arpt",
    cityCode: "CAG",
    cityName: "Cagliari",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 30.1219,
    longitude: 31.40555,
    airportCode: "CAI",
    airportName: "Cairo International",
    cityCode: "CAI",
    cityName: "Cairo",
    countryCode: "EG",
    region: "Egypt",
  },
  {
    latitude: 23.3925,
    longitude: 113.29888,
    airportCode: "CAN",
    airportName: "Baiyun Intl Arpt",
    cityCode: "CAN",
    cityName: "Guangzhou",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 37.7008,
    longitude: -113.09888,
    airportCode: "CDC",
    airportName: "Cedar City Mun",
    cityCode: "CDC",
    cityName: "Cedar City",
    countryCode: "US",
    region: "UT",
  },
  {
    latitude: 49.0097,
    longitude: 2.54777,
    airportCode: "CDG",
    airportName: "Charles De Gaulle Intl Arpt",
    cityCode: "PAR",
    cityName: "Paris-De Gaulle",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 45.7858,
    longitude: 3.1625,
    airportCode: "CFE",
    airportName: "Aulnat",
    cityCode: "CFE",
    cityName: "Clermont Ferrand",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 39.6019,
    longitude: 19.91166,
    airportCode: "CFU",
    airportName: "Ioannis Kapodistrias Intl Airport",
    cityCode: "CFU",
    cityName: "Corfu",
    countryCode: "GR",
    region: "Greece",
  },
  {
    latitude: -15.653,
    longitude: -56.11666,
    airportCode: "CGB",
    airportName: "Intl. Airpt. Marechal Rondon",
    cityCode: "CGB",
    cityName: "Cuiaba",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: -6.1255,
    longitude: 106.65583,
    airportCode: "CGK",
    airportName: "Soekarno Hatta International Airport",
    cityCode: "CGK",
    cityName: "Jakarta",
    countryCode: "ID",
    region: "Indonesia",
  },
  {
    latitude: 34.5194,
    longitude: 113.84111,
    airportCode: "CGO",
    airportName: "Zhengzhou",
    cityCode: "CGO",
    cityName: "Zhengzhou",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 43.9961,
    longitude: 125.68527,
    airportCode: "CGQ",
    airportName: "Changchun",
    cityCode: "CGQ",
    cityName: "Changchun",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: -20.4686,
    longitude: -54.6725,
    airportCode: "CGR",
    airportName: "Intl. Airport Campo Grande",
    cityCode: "CGR",
    cityName: "Campo Grande",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 35.0352,
    longitude: -85.20388,
    airportCode: "CHA",
    airportName: "Lovell Field",
    cityCode: "CHA",
    cityName: "Chattanooga",
    countryCode: "US",
    region: "TN",
  },
  {
    latitude: 38.1386,
    longitude: -78.45277,
    airportCode: "CHO",
    airportName: "Albemarle",
    cityCode: "CHO",
    cityName: "Charlottesville",
    countryCode: "US",
    region: "VA",
  },
  {
    latitude: 32.8986,
    longitude: -80.04055,
    airportCode: "CHS",
    airportName: "Charleston Afb/Intl",
    cityCode: "CHS",
    cityName: "Charleston",
    countryCode: "US",
    region: "SC",
  },
  {
    latitude: 41.8847,
    longitude: -91.71083,
    airportCode: "CID",
    airportName: "Cedar Rapids",
    cityCode: "CID",
    cityName: "Cedar Rapids",
    countryCode: "US",
    region: "IA",
  },
  {
    latitude: 46.2508,
    longitude: -84.4725,
    airportCode: "CIU",
    airportName: "Chippewa County Airport",
    cityCode: "CIU",
    cityName: "Sault Ste Marie",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: -6.7875,
    longitude: -79.82805,
    airportCode: "CIX",
    airportName: "Capit�n FAP Jos� A. Qui�ones Gonz�lez Intl Arpt",
    cityCode: "CIX",
    cityName: "Chiclayo",
    countryCode: "PE",
    region: "Peru",
  },
  {
    latitude: 29.7194,
    longitude: 106.64194,
    airportCode: "CKG",
    airportName: "Chongqing Jiangbei Intl Arpt",
    cityCode: "CKG",
    cityName: "Chongqing",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 9.5769,
    longitude: -13.61194,
    airportCode: "CKY",
    airportName: "Conakry",
    cityCode: "CKY",
    cityName: "Conakry",
    countryCode: "GN",
    region: "Guinea",
  },
  {
    latitude: 41.4116,
    longitude: -81.84972,
    airportCode: "CLE",
    airportName: "Hopkins International",
    cityCode: "CLE",
    cityName: "Cleveland-Hopkins",
    countryCode: "US",
    region: "OH",
  },
  {
    latitude: 3.5433,
    longitude: -76.38166,
    airportCode: "CLO",
    airportName: "Alfonso B. Aragon",
    cityCode: "CLO",
    cityName: "Cali",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 35.2138,
    longitude: -80.94305,
    airportCode: "CLT",
    airportName: "Charlotte/Douglas Intl",
    cityCode: "CLT",
    cityName: "Charlotte",
    countryCode: "US",
    region: "NC",
  },
  {
    latitude: 42.5202,
    longitude: 8.79305,
    airportCode: "CLY",
    airportName: "Ste Catherine",
    cityCode: "CLY",
    cityName: "Calvi",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 7.1802,
    longitude: 79.88527,
    airportCode: "CMB",
    airportName: "Bandaranaike Intl Arpt",
    cityCode: "CMB",
    cityName: "Colombo",
    countryCode: "LK",
    region: "Sri Lanka",
  },
  {
    latitude: 39.998,
    longitude: -82.89194,
    airportCode: "CMH",
    airportName: "John Glenn Columbus International Airport",
    cityCode: "CMH",
    cityName: "Columbus",
    countryCode: "US",
    region: "OH",
  },
  {
    latitude: 33.3675,
    longitude: -7.59,
    airportCode: "CMN",
    airportName: "Mohamed V Airport",
    cityCode: "CMN",
    cityName: "Casablanca",
    countryCode: "MA",
    region: "Morocco",
  },
  {
    latitude: -19.6338,
    longitude: -43.96888,
    airportCode: "CNF",
    airportName: "Tancredo Neves International",
    cityCode: "CNF",
    cityName: "Belo Horizonte",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: -16.878,
    longitude: 145.74888,
    airportCode: "CNS",
    airportName: "Cairns Intl Arpt",
    cityCode: "CNS",
    cityName: "Cairns",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 18.7713,
    longitude: 98.96277,
    airportCode: "CNX",
    airportName: "Chiang Mai International",
    cityCode: "CNX",
    cityName: "Chiang Mai",
    countryCode: "TH",
    region: "Thailand",
  },
  {
    latitude: 38.7608,
    longitude: -109.7475,
    airportCode: "CNY",
    airportName: "Canyonlands Regional Airport",
    cityCode: "CNY",
    cityName: "Moab",
    countryCode: "US",
    region: "UT",
  },
  {
    latitude: 44.5177,
    longitude: -109.02611,
    airportCode: "COD",
    airportName: "Yellowstone Regional Airport",
    cityCode: "COD",
    cityName: "Cody",
    countryCode: "US",
    region: "WY",
  },
  {
    latitude: 6.3572,
    longitude: 2.38444,
    airportCode: "COO",
    airportName: "Cotonou",
    cityCode: "COO",
    cityName: "Cotonou",
    countryCode: "BJ",
    region: "Benin",
  },
  {
    latitude: -31.31,
    longitude: -64.20833,
    airportCode: "COR",
    airportName: "Pajas Blancas",
    cityCode: "COR",
    cityName: "Cordoba",
    countryCode: "AR",
    region: "Argentina",
  },
  {
    latitude: 38.8058,
    longitude: -104.70083,
    airportCode: "COS",
    airportName: "Colorado Springs",
    cityCode: "COS",
    cityName: "Colorado Springs",
    countryCode: "US",
    region: "CO",
  },
  {
    latitude: 55.6202,
    longitude: 12.65027,
    airportCode: "CPH",
    airportName: "Kastrup",
    cityCode: "CPH",
    cityName: "Copenhagen",
    countryCode: "DK",
    region: "Denmark",
  },
  {
    latitude: 42.908,
    longitude: -106.46444,
    airportCode: "CPR",
    airportName: "Natrona Co Intl",
    cityCode: "CPR",
    cityName: "Casper",
    countryCode: "US",
    region: "WY",
  },
  {
    latitude: -33.9647,
    longitude: 18.60166,
    airportCode: "CPT",
    airportName: "Cape Town International",
    cityCode: "CPT",
    cityName: "Cape Town",
    countryCode: "ZA",
    region: "South Africa",
  },
  {
    latitude: 38.373,
    longitude: -81.59305,
    airportCode: "CRW",
    airportName: "Yeager",
    cityCode: "CRW",
    cityName: "Charleston",
    countryCode: "US",
    region: "WV",
  },
  {
    latitude: 32.5144,
    longitude: -84.94027,
    airportCode: "CSG",
    airportName: "Columbus Metro",
    cityCode: "CSG",
    cityName: "Columbus",
    countryCode: "US",
    region: "GA",
  },
  {
    latitude: 28.1936,
    longitude: 113.21777,
    airportCode: "CSX",
    airportName: "Changsha",
    cityCode: "CSX",
    cityName: "Changsha",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 37.4666,
    longitude: 15.06388,
    airportCode: "CTA",
    airportName: "Fontanarossa",
    cityCode: "CTA",
    cityName: "Catania",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 10.4425,
    longitude: -75.51305,
    airportCode: "CTG",
    airportName: "Rafael Nunez",
    cityCode: "CTG",
    cityName: "Cartagena",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 42.7752,
    longitude: 141.69222,
    airportCode: "CTS",
    airportName: "Chitose Airport",
    cityCode: "SPK",
    cityName: "Sapporo",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 30.5786,
    longitude: 103.94722,
    airportCode: "CTU",
    airportName: "Shuangliu",
    cityCode: "CTU",
    cityName: "Chengdu",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 7.9275,
    longitude: -72.51166,
    airportCode: "CUC",
    airportName: "Camilo Daza International Airport",
    cityCode: "CUC",
    cityName: "C�cuta",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: -2.8894,
    longitude: -78.98444,
    airportCode: "CUE",
    airportName: "Mariscal Lamar Intl Arpt",
    cityCode: "CUE",
    cityName: "Cuenca",
    countryCode: "EC",
    region: "Ecuador",
  },
  {
    latitude: 21.0366,
    longitude: -86.87722,
    airportCode: "CUN",
    airportName: "Cancun International",
    cityCode: "CUN",
    cityName: "Cancun",
    countryCode: "MX",
    region: "Mexico",
  },
  {
    latitude: -13.5358,
    longitude: -71.93888,
    airportCode: "CUZ",
    airportName: "Alejandro Velasco Astete Intl Arpt",
    cityCode: "CUZ",
    cityName: "Cusco",
    countryCode: "PE",
    region: "Peru",
  },
  {
    latitude: 39.0488,
    longitude: -84.66777,
    airportCode: "CVG",
    airportName: "Cincinnati/Northern Ky Intl",
    cityCode: "CVG",
    cityName: "Cincinnati",
    countryCode: "US",
    region: "OH",
  },
  {
    latitude: 44.7833,
    longitude: -89.66666,
    airportCode: "CWA",
    airportName: "Central Wisconsin",
    cityCode: "STE",
    cityName: "Wausau",
    countryCode: "US",
    region: "WI",
  },
  {
    latitude: -25.5286,
    longitude: -49.17583,
    airportCode: "CWB",
    airportName: "Afonso Pena International Airport",
    cityCode: "CWB",
    cityName: "Curitiba",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 51.3966,
    longitude: -3.34333,
    airportCode: "CWL",
    airportName: "Cardiff",
    cityCode: "CWL",
    cityName: "Cardiff",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 11.998,
    longitude: 109.21916,
    airportCode: "CXR",
    airportName: "Cam Ranh Intl Arpt",
    cityCode: "CXR",
    cityName: "Cam Ranh Bay",
    countryCode: "VN",
    region: "Vietnam",
  },
  {
    latitude: 29.18,
    longitude: -81.05805,
    airportCode: "DAB",
    airportName: "Daytona Beach International",
    cityCode: "DAB",
    cityName: "Daytona Beach",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 16.0438,
    longitude: 108.19944,
    airportCode: "DAD",
    airportName: "Da Nang Intl Arpt",
    cityCode: "DAD",
    cityName: "Da Nang",
    countryCode: "VN",
    region: "Vietnam",
  },
  {
    latitude: 32.8472,
    longitude: -96.85166,
    airportCode: "DAL",
    airportName: "Love Field",
    cityCode: "DAL",
    cityName: "Dallas-Love Field",
    countryCode: "US",
    region: "TX",
  },
  {
    latitude: -6.878,
    longitude: 39.2025,
    airportCode: "DAR",
    airportName: "International",
    cityCode: "DAR",
    cityName: "Dar es Salaam",
    countryCode: "TZ",
    region: "Tanzania",
  },
  {
    latitude: 39.9025,
    longitude: -84.21944,
    airportCode: "DAY",
    airportName: "James Cox Dayton Intl",
    cityCode: "DAY",
    cityName: "Dayton",
    countryCode: "US",
    region: "OH",
  },
  {
    latitude: 42.5613,
    longitude: 18.26833,
    airportCode: "DBV",
    airportName: "Dubrovnik Airport",
    cityCode: "DBV",
    cityName: "Dubrovnik",
    countryCode: "HR",
    region: "Croatia",
  },
  {
    latitude: 38.8522,
    longitude: -77.03777,
    airportCode: "DCA",
    airportName: "Ronald Reagan Washington National Arpt",
    cityCode: "WAS",
    cityName: "Washington-Reagan National",
    countryCode: "US",
    region: "DC",
  },
  {
    latitude: 28.5663,
    longitude: 77.10305,
    airportCode: "DEL",
    airportName: "Indira Gandhi Intl Arpt",
    cityCode: "DEL",
    cityName: "Delhi",
    countryCode: "IN",
    region: "India",
  },
  {
    latitude: 39.8616,
    longitude: -104.67305,
    airportCode: "DEN",
    airportName: "Denver Intl",
    cityCode: "DEN",
    cityName: "Denver",
    countryCode: "US",
    region: "CO",
  },
  {
    latitude: 32.8969,
    longitude: -97.03805,
    airportCode: "DFW",
    airportName: "Dallas/Ft Worth Intl",
    cityCode: "DFW",
    cityName: "Dallas-Fort Worth",
    countryCode: "US",
    region: "TX",
  },
  {
    latitude: 31.3213,
    longitude: -85.44972,
    airportCode: "DHN",
    airportName: "Dothan",
    cityCode: "DHN",
    cityName: "Dothan",
    countryCode: "US",
    region: "AL",
  },
  {
    latitude: 4.0061,
    longitude: 9.71944,
    airportCode: "DLA",
    airportName: "Douala Intl Arpt",
    cityCode: "DLA",
    cityName: "Douala",
    countryCode: "CM",
    region: "Cameroon",
  },
  {
    latitude: 38.9655,
    longitude: 121.53861,
    airportCode: "DLC",
    airportName: "Dalian",
    cityCode: "DLC",
    cityName: "Dalian",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 46.8422,
    longitude: -92.19361,
    airportCode: "DLH",
    airportName: "Duluth International",
    cityCode: "DLH",
    cityName: "Duluth",
    countryCode: "US",
    region: "MN",
  },
  {
    latitude: 26.4711,
    longitude: 49.79777,
    airportCode: "DMM",
    airportName: "King Fahd International Airport",
    cityCode: "DMM",
    cityName: "Dammam",
    countryCode: "SA",
    region: "Saudi Arabia",
  },
  {
    latitude: 15.5469,
    longitude: -61.3,
    airportCode: "DOM",
    airportName: "Melville Hall Arpt",
    cityCode: "DOM",
    cityName: "Marigot",
    countryCode: "DM",
    region: "Dominica",
  },
  {
    latitude: -8.748,
    longitude: 115.16722,
    airportCode: "DPS",
    airportName: "Ngurah Rai Intl Arpt",
    cityCode: "DPS",
    cityName: "Bali",
    countryCode: "ID",
    region: "Indonesia",
  },
  {
    latitude: 37.1522,
    longitude: -107.75361,
    airportCode: "DRO",
    airportName: "Durango-La Plata County Airport",
    cityCode: "DRO",
    cityName: "Durango",
    countryCode: "US",
    region: "CO",
  },
  {
    latitude: 51.1344,
    longitude: 13.76805,
    airportCode: "DRS",
    airportName: "Dresden Arpt",
    cityCode: "DRS",
    cityName: "Dresden",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: -12.4147,
    longitude: 130.87666,
    airportCode: "DRW",
    airportName: "Darwin Intl Arpt",
    cityCode: "DRW",
    cityName: "Darwin",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 41.5341,
    longitude: -93.65777,
    airportCode: "DSM",
    airportName: "Des Moines Intl",
    cityCode: "DSM",
    cityName: "Des Moines",
    countryCode: "US",
    region: "IA",
  },
  {
    latitude: 14.6711,
    longitude: -17.06694,
    airportCode: "DSS",
    airportName: "Blaise Diagne International Airport",
    cityCode: "DSS",
    cityName: "Dakar",
    countryCode: "SN",
    region: "Senegal",
  },
  {
    latitude: 42.2125,
    longitude: -83.35333,
    airportCode: "DTW",
    airportName: "Detroit Metro Arpt",
    cityCode: "DTT",
    cityName: "Detroit",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 53.4213,
    longitude: -6.27,
    airportCode: "DUB",
    airportName: "Dublin International",
    cityCode: "DUB",
    cityName: "Dublin",
    countryCode: "IE",
    region: "Ireland",
  },
  {
    latitude: 51.2894,
    longitude: 6.76666,
    airportCode: "DUS",
    airportName: "Dusseldorf International Airport",
    cityCode: "DUS",
    cityName: "Dusseldorf",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: 25.2527,
    longitude: 55.36444,
    airportCode: "DXB",
    airportName: "Dubai",
    cityCode: "DXB",
    cityName: "Dubai",
    countryCode: "AE",
    region: "United Arab Emirates",
  },
  {
    latitude: 0.0425,
    longitude: 32.44361,
    airportCode: "EBB",
    airportName: "Entebbe",
    cityCode: "EBB",
    cityName: "Entebbe",
    countryCode: "UG",
    region: "Uganda",
  },
  {
    latitude: 30.3577,
    longitude: -85.79888,
    airportCode: "ECP",
    airportName: "Northwest Florida Beaches International",
    cityCode: "ECP",
    cityName: "Panama City",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 55.95,
    longitude: -3.3725,
    airportCode: "EDI",
    airportName: "Edinburgh",
    cityCode: "EDI",
    cityName: "Edinburgh",
    countryCode: "GB",
    region: "Scotland",
  },
  {
    latitude: 39.6425,
    longitude: -106.91777,
    airportCode: "EGE",
    airportName: "Eagle County",
    cityCode: "EGE",
    cityName: "Vail",
    countryCode: "US",
    region: "CO",
  },
  {
    latitude: 18.4447,
    longitude: -64.54305,
    airportCode: "EIS",
    airportName: "Terrance B. Lettsome Intl Arpt",
    cityCode: "EIS",
    cityName: "Beef Island",
    countryCode: "VG",
    region: "British Virgin Islands",
  },
  {
    latitude: 40.8275,
    longitude: -115.78138,
    airportCode: "EKO",
    airportName: "Elko Regional",
    cityCode: "EKO",
    cityName: "Elko",
    countryCode: "US",
    region: "NV",
  },
  {
    latitude: 25.475,
    longitude: -76.68361,
    airportCode: "ELH",
    airportName: "North Eleuthera Intl Airport",
    cityCode: "ELH",
    cityName: "North Eleuthera",
    countryCode: "BS",
    region: "Bahamas",
  },
  {
    latitude: 42.16,
    longitude: -76.89166,
    airportCode: "ELM",
    airportName: "Elmira Corning Regional",
    cityCode: "ELM",
    cityName: "Elmira",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 31.8025,
    longitude: -106.39833,
    airportCode: "ELP",
    airportName: "El Paso Intl",
    cityCode: "ELP",
    cityName: "El Paso",
    countryCode: "US",
    region: "TX",
  },
  {
    latitude: 45.7205,
    longitude: -87.09583,
    airportCode: "ESC",
    airportName: "Delta County Arpt",
    cityCode: "ESC",
    cityName: "Escanaba",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 44.1247,
    longitude: -123.21194,
    airportCode: "EUG",
    airportName: "Eugene",
    cityCode: "EUG",
    cityName: "Eugene",
    countryCode: "US",
    region: "OR",
  },
  {
    latitude: 40.1472,
    longitude: 44.39583,
    airportCode: "EVN",
    airportName: "Zvartnots",
    cityCode: "EVN",
    cityName: "Yerevan",
    countryCode: "AM",
    region: "Armenia",
  },
  {
    latitude: 38.0369,
    longitude: -87.5325,
    airportCode: "EVV",
    airportName: "Evansville Regl",
    cityCode: "EVV",
    cityName: "Evansville",
    countryCode: "US",
    region: "IN",
  },
  {
    latitude: 40.6925,
    longitude: -74.16861,
    airportCode: "EWR",
    airportName: "Newark Liberty International",
    cityCode: "EWR",
    cityName: "Newark",
    countryCode: "US",
    region: "NJ",
  },
  {
    latitude: 24.5561,
    longitude: -81.75944,
    airportCode: "EYW",
    airportName: "Key West Intl",
    cityCode: "EYW",
    cityName: "Key West",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: -34.8222,
    longitude: -58.53583,
    airportCode: "EZE",
    airportName: "Ezeiza Ministro Pistarini",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryCode: "AR",
    region: "Argentina",
  },
  {
    latitude: 64.815,
    longitude: -147.85638,
    airportCode: "FAI",
    airportName: "Fairbanks Intl Airport",
    cityCode: "FAI",
    cityName: "Fairbanks",
    countryCode: "US",
    region: "AK",
  },
  {
    latitude: 37.0144,
    longitude: -7.96583,
    airportCode: "FAO",
    airportName: "Faro Arpt",
    cityCode: "FAO",
    cityName: "Faro",
    countryCode: "PT",
    region: "Portugal",
  },
  {
    latitude: 46.9205,
    longitude: -96.81583,
    airportCode: "FAR",
    airportName: "Hector Field",
    cityCode: "FAR",
    cityName: "Fargo",
    countryCode: "US",
    region: "ND",
  },
  {
    latitude: 36.7761,
    longitude: -119.71805,
    airportCode: "FAT",
    airportName: "Fresno Air Terminal Airport",
    cityCode: "FAT",
    cityName: "Fresno",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 34.99,
    longitude: -78.88305,
    airportCode: "FAY",
    airportName: "Fayetteville Regl/Grannis",
    cityCode: "FAY",
    cityName: "Fayetteville",
    countryCode: "US",
    region: "NC",
  },
  {
    latitude: 48.3105,
    longitude: -114.25611,
    airportCode: "FCA",
    airportName: "Glacier Park Intl",
    cityCode: "FCA",
    cityName: "Kalispell",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: 41.8002,
    longitude: 12.23888,
    airportCode: "FCO",
    airportName: "Leonardo da Vinci/Fiumicino Arpt",
    cityCode: "ROM",
    cityName: "Rome",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: -4.3858,
    longitude: 15.44444,
    airportCode: "FIH",
    airportName: "N.djili International",
    cityCode: "FIH",
    cityName: "Kinshasa",
    countryCode: "CD",
    region: "Congo Democratic Republic",
  },
  {
    latitude: 26.0725,
    longitude: -80.15277,
    airportCode: "FLL",
    airportName: "Ft Lauderdale-Hollywood Intl",
    cityCode: "FLL",
    cityName: "Fort Lauderdale",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: -27.6705,
    longitude: -48.54722,
    airportCode: "FLN",
    airportName: "Hercilio Luz",
    cityCode: "FLN",
    cityName: "Florianopolis",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 43.8086,
    longitude: 11.20277,
    airportCode: "FLR",
    airportName: "Peretola",
    cityCode: "FLR",
    cityName: "Florence",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 8.6163,
    longitude: -13.19555,
    airportCode: "FNA",
    airportName: "Lungi Airport",
    cityCode: "FNA",
    cityName: "Freetown",
    countryCode: "SL",
    region: "Sierra Leone",
  },
  {
    latitude: 32.6941,
    longitude: -16.77805,
    airportCode: "FNC",
    airportName: "Madeira - Cristiano Ronaldo International Airport",
    cityCode: "FNC",
    cityName: "Madeira",
    countryCode: "PT",
    region: "Portugal",
  },
  {
    latitude: 25.935,
    longitude: 119.66333,
    airportCode: "FOC",
    airportName: "Fuzhou Changle Intl Arpt",
    cityCode: "FOC",
    cityName: "Fuzhou",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: -3.7763,
    longitude: -38.5325,
    airportCode: "FOR",
    airportName: "Pinto Martins Arpt",
    cityCode: "FOR",
    cityName: "Fortaleza",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 50.033,
    longitude: 8.57055,
    airportCode: "FRA",
    airportName: "Frankfurt Rhein Main Intl Arpt",
    cityCode: "FRA",
    cityName: "Frankfurt",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: 41.5022,
    longitude: 9.09666,
    airportCode: "FSC",
    airportName: "Sud Corse Airport",
    cityCode: "FSC",
    cityName: "Figari",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 43.5819,
    longitude: -96.74194,
    airportCode: "FSD",
    airportName: "Joe Foss Field Airport",
    cityCode: "FSD",
    cityName: "Sioux Falls",
    countryCode: "US",
    region: "SD",
  },
  {
    latitude: 33.5858,
    longitude: 130.45055,
    airportCode: "FUK",
    airportName: "Fukuoka",
    cityCode: "FUK",
    cityName: "Fukuoka",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 40.983,
    longitude: -85.18972,
    airportCode: "FWA",
    airportName: "Municipal/Baer Field",
    cityCode: "FWA",
    cityName: "Fort Wayne",
    countryCode: "US",
    region: "IN",
  },
  {
    latitude: 19.2927,
    longitude: -81.35777,
    airportCode: "GCM",
    airportName: "Owen Roberts Airport",
    cityCode: "GCM",
    cityName: "Grand Cayman",
    countryCode: "KY",
    region: "Cayman Islands",
  },
  {
    latitude: 20.5216,
    longitude: -103.31111,
    airportCode: "GDL",
    airportName: "Don Miguel Hidalgo y Costilla Intl",
    cityCode: "GDL",
    cityName: "Guadalajara",
    countryCode: "MX",
    region: "Mexico",
  },
  {
    latitude: 54.3775,
    longitude: 18.46611,
    airportCode: "GDN",
    airportName: "Gdansk Lech Walesa Airport",
    cityCode: "GDN",
    cityName: "Gdansk",
    countryCode: "PL",
    region: "Poland",
  },
  {
    latitude: 47.6172,
    longitude: -117.54,
    airportCode: "GEG",
    airportName: "Spokane Intl",
    cityCode: "GEG",
    cityName: "Spokane",
    countryCode: "US",
    region: "WA",
  },
  {
    latitude: 47.9491,
    longitude: -97.17611,
    airportCode: "GFK",
    airportName: "Grand Forks",
    cityCode: "GFK",
    cityName: "Grand Forks",
    countryCode: "US",
    region: "ND",
  },
  {
    latitude: 23.5625,
    longitude: -75.87805,
    airportCode: "GGT",
    airportName: "Georgetown Exuma International",
    cityCode: "GGT",
    cityName: "Georgetown",
    countryCode: "BS",
    region: "Bahamas",
  },
  {
    latitude: -22.8125,
    longitude: -43.24833,
    airportCode: "GIG",
    airportName: "Rio de Janeiro International Airport",
    cityCode: "GIG",
    cityName: "Rio de Janeiro",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 39.1233,
    longitude: -108.52583,
    airportCode: "GJT",
    airportName: "Walker",
    cityCode: "GJT",
    cityName: "Grand Junction",
    countryCode: "US",
    region: "CO",
  },
  {
    latitude: 55.8719,
    longitude: -4.43333,
    airportCode: "GLA",
    airportName: "Glasgow Airport",
    cityCode: "GLA",
    cityName: "Glasgow",
    countryCode: "GB",
    region: "Scotland",
  },
  {
    latitude: 29.69,
    longitude: -82.27166,
    airportCode: "GNV",
    airportName: "Gainesville Regl",
    cityCode: "GNV",
    cityName: "Gainesville",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 44.4133,
    longitude: 8.8375,
    airportCode: "GOA",
    airportName: "Cristoforo Colombo",
    cityCode: "GOA",
    cityName: "Genoa",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 57.6627,
    longitude: 12.27972,
    airportCode: "GOT",
    airportName: "Landvetter",
    cityCode: "GOT",
    cityName: "Gothenburg",
    countryCode: "SE",
    region: "Sweden",
  },
  {
    latitude: -0.4538,
    longitude: -90.26583,
    airportCode: "GPS",
    airportName: "Seymour Airport",
    cityCode: "GPS",
    cityName: "Baltra Island",
    countryCode: "EC",
    region: "Ecuador",
  },
  {
    latitude: 30.4072,
    longitude: -89.07,
    airportCode: "GPT",
    airportName: "Gulfport-Biloxi Regl",
    cityCode: "GPT",
    cityName: "Gulfport",
    countryCode: "US",
    region: "MS",
  },
  {
    latitude: 44.4855,
    longitude: -88.13388,
    airportCode: "GRB",
    airportName: "Austin Straubel Intl. Airport",
    cityCode: "GRB",
    cityName: "Green Bay",
    countryCode: "US",
    region: "WI",
  },
  {
    latitude: 41.9008,
    longitude: 2.76055,
    airportCode: "GRO",
    airportName: "Girona-Costa Brava",
    cityCode: "GRO",
    cityName: "Girona",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 42.8808,
    longitude: -85.52277,
    airportCode: "GRR",
    airportName: "Gerald R. Ford Intl Airport",
    cityCode: "GRR",
    cityName: "Grand Rapids",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: -23.4319,
    longitude: -46.46944,
    airportCode: "GRU",
    airportName: "Sao Paulo Guarulhos International Airport",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 46.9936,
    longitude: 15.44,
    airportCode: "GRZ",
    airportName: "Graz Airport",
    cityCode: "GRZ",
    cityName: "Graz",
    countryCode: "AT",
    region: "Austria",
  },
  {
    latitude: 36.0977,
    longitude: -79.93722,
    airportCode: "GSO",
    airportName: "Piedmont Triad Intl",
    cityCode: "GSO",
    cityName: "Greensboro",
    countryCode: "US",
    region: "NC",
  },
  {
    latitude: 34.8955,
    longitude: -82.21888,
    airportCode: "GSP",
    airportName: "Greenville-Spartanburg",
    cityCode: "GSP",
    cityName: "Greenville/Spartanburg",
    countryCode: "US",
    region: "SC",
  },
  {
    latitude: 47.4819,
    longitude: -111.37055,
    airportCode: "GTF",
    airportName: "Great Falls Intl",
    cityCode: "GTF",
    cityName: "Great Falls",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: 33.4658,
    longitude: -88.38083,
    airportCode: "GTR",
    airportName: "Golden Triangle Regl",
    cityCode: "GTR",
    cityName: "Columbus",
    countryCode: "US",
    region: "MS",
  },
  {
    latitude: 14.5844,
    longitude: -90.52722,
    airportCode: "GUA",
    airportName: "La Aurora",
    cityCode: "GUA",
    cityName: "Guatemala City",
    countryCode: "GT",
    region: "Guatemala",
  },
  {
    latitude: 13.4833,
    longitude: 144.79611,
    airportCode: "GUM",
    airportName: "Guam International",
    cityCode: "GUM",
    cityName: "Guam",
    countryCode: "GU",
    region: "GU",
  },
  {
    latitude: 46.238,
    longitude: 6.10888,
    airportCode: "GVA",
    airportName: "Geneva",
    cityCode: "GVA",
    cityName: "Geneva",
    countryCode: "CH",
    region: "Switzerland",
  },
  {
    latitude: -2.1575,
    longitude: -79.88361,
    airportCode: "GYE",
    airportName: "Jos� Joaqu�n de Olmedo International Airport",
    cityCode: "GYE",
    cityName: "Guyaquil",
    countryCode: "EC",
    region: "Ecuador",
  },
  {
    latitude: -16.6319,
    longitude: -49.22055,
    airportCode: "GYN",
    airportName: "Santa Genoveva",
    cityCode: "GYN",
    cityName: "Goiania",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 52.4605,
    longitude: 9.68944,
    airportCode: "HAJ",
    airportName: "Hannover Arpt",
    cityCode: "HAJ",
    cityName: "Hannover",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: 19.9347,
    longitude: 110.45888,
    airportCode: "HAK",
    airportName: "Haikou",
    cityCode: "HAK",
    cityName: "Haikou",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 53.6302,
    longitude: 9.98833,
    airportCode: "HAM",
    airportName: "Hamburg Airport",
    cityCode: "HAM",
    cityName: "Hamburg",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: 21.2211,
    longitude: 105.80722,
    airportCode: "HAN",
    airportName: "Noibai International",
    cityCode: "HAN",
    cityName: "Hanoi",
    countryCode: "VN",
    region: "Vietnam",
  },
  {
    latitude: -42.8361,
    longitude: 147.51027,
    airportCode: "HBA",
    airportName: "Hobart Intl Arpt",
    cityCode: "HBA",
    cityName: "Hobart",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 40.4833,
    longitude: -107.22361,
    airportCode: "HDN",
    airportName: "Yampa Valley",
    cityCode: "SBS",
    cityName: "Steamboat Springs",
    countryCode: "US",
    region: "CO",
  },
  {
    latitude: 60.3172,
    longitude: 24.96333,
    airportCode: "HEL",
    airportName: "Helsinki-vantaa",
    cityCode: "HEL",
    cityName: "Helsinki",
    countryCode: "FI",
    region: "Finland",
  },
  {
    latitude: 35.3397,
    longitude: 25.18027,
    airportCode: "HER",
    airportName: "Nikos Kazantzakis Intl Airport",
    cityCode: "HER",
    cityName: "Heraklion",
    countryCode: "GR",
    region: "Greece",
  },
  {
    latitude: 31.9911,
    longitude: 116.98916,
    airportCode: "HFE",
    airportName: "Hefei Xinqiao Intl Aprt",
    cityCode: "HFE",
    cityName: "Hefei",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 32.2222,
    longitude: -80.69888,
    airportCode: "HHH",
    airportName: "Hilton Head Airport",
    cityCode: "HHH",
    cityName: "Hilton Head Island",
    countryCode: "US",
    region: "SC",
  },
  {
    latitude: 47.3866,
    longitude: -92.83888,
    airportCode: "HIB",
    airportName: "Chisholm",
    cityCode: "HIB",
    cityName: "Hibbing",
    countryCode: "US",
    region: "MN",
  },
  {
    latitude: 22.3088,
    longitude: 113.91472,
    airportCode: "HKG",
    airportName: "Hong Kong Intl",
    cityCode: "HKG",
    cityName: "Hong Kong",
    countryCode: "HK",
    region: "Hong Kong",
  },
  {
    latitude: 8.1122,
    longitude: 98.30527,
    airportCode: "HKT",
    airportName: "Phuket Intl Arpt",
    cityCode: "HKT",
    cityName: "Phuket",
    countryCode: "TH",
    region: "Thailand",
  },
  {
    latitude: 46.6069,
    longitude: -111.98277,
    airportCode: "HLN",
    airportName: "Helena Regl",
    cityCode: "HLN",
    cityName: "Helena",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: 35.5522,
    longitude: 139.77972,
    airportCode: "HND",
    airportName: "Tokyo Haneda International",
    cityCode: "TYO",
    cityName: "Tokyo-Haneda Airport",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 21.3277,
    longitude: -157.92222,
    airportCode: "HNL",
    airportName: "Honolulu Intl",
    cityCode: "HNL",
    cityName: "Honolulu",
    countryCode: "US",
    region: "HI",
  },
  {
    latitude: 29.6455,
    longitude: -95.27888,
    airportCode: "HOU",
    airportName: "Hobby",
    cityCode: "HOU",
    cityName: "Houston-Hobby",
    countryCode: "US",
    region: "TX",
  },
  {
    latitude: 41.0669,
    longitude: -73.7075,
    airportCode: "HPN",
    airportName: "Westchester Co",
    cityCode: "HPN",
    cityName: "White Plains",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 45.6233,
    longitude: 126.25027,
    airportCode: "HRB",
    airportName: "Harbin",
    cityCode: "HRB",
    cityName: "Harbin",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: -17.9319,
    longitude: 31.09277,
    airportCode: "HRE",
    airportName: "Harare",
    cityCode: "HRE",
    cityName: "Harare",
    countryCode: "ZW",
    region: "Zimbabwe",
  },
  {
    latitude: 34.6372,
    longitude: -86.775,
    airportCode: "HSV",
    airportName: "Huntsville International - Carl T. Jones",
    cityCode: "HSV",
    cityName: "Huntsville",
    countryCode: "US",
    region: "AL",
  },
  {
    latitude: 53.5744,
    longitude: -0.35083,
    airportCode: "HUY",
    airportName: "Humberside International",
    cityCode: "HUY",
    cityName: "Humberside",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 38.9444,
    longitude: -77.45583,
    airportCode: "IAD",
    airportName: "Washington Dulles Intl",
    cityCode: "WAS",
    cityName: "Washington-Dulles",
    countryCode: "US",
    region: "DC",
  },
  {
    latitude: 29.9844,
    longitude: -95.34138,
    airportCode: "IAH",
    airportName: "George Bush Intercontinental",
    cityCode: "IAH",
    cityName: "Houston-Bush Intercontl",
    countryCode: "US",
    region: "TX",
  },
  {
    latitude: 38.8727,
    longitude: 1.37305,
    airportCode: "IBZ",
    airportName: "Ibiza",
    cityCode: "IBZ",
    cityName: "Ibiza",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 37.4691,
    longitude: 126.45055,
    airportCode: "ICN",
    airportName: "Incheon Intl Arpt",
    cityCode: "SEL",
    cityName: "Seoul-Incheon",
    countryCode: "KR",
    region: "South Korea",
  },
  {
    latitude: 37.6527,
    longitude: -97.43305,
    airportCode: "ICT",
    airportName: "Wichita Eisenhower National Arpt",
    cityCode: "ICT",
    cityName: "Wichita",
    countryCode: "US",
    region: "KS",
  },
  {
    latitude: 43.5144,
    longitude: -112.07083,
    airportCode: "IDA",
    airportName: "Fanning",
    cityCode: "IDA",
    cityName: "Idaho Falls",
    countryCode: "US",
    region: "ID",
  },
  {
    latitude: -25.5961,
    longitude: -54.48722,
    airportCode: "IGU",
    airportName: "Cataratas",
    cityCode: "IGU",
    cityName: "Iguassu Falls",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 34.2705,
    longitude: -77.9025,
    airportCode: "ILM",
    airportName: "New Hanover Intl",
    cityCode: "ILM",
    cityName: "Wilmington",
    countryCode: "US",
    region: "NC",
  },
  {
    latitude: 45.8166,
    longitude: -88.11666,
    airportCode: "IMT",
    airportName: "Ford",
    cityCode: "IMT",
    cityName: "Iron Mountain",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 39.7172,
    longitude: -86.29444,
    airportCode: "IND",
    airportName: "Indianapolis Intl",
    cityCode: "IND",
    cityName: "Indianapolis",
    countryCode: "US",
    region: "IN",
  },
  {
    latitude: 48.5661,
    longitude: -93.40305,
    airportCode: "INL",
    airportName: "Falls Intl",
    cityCode: "INL",
    cityName: "International Falls",
    countryCode: "US",
    region: "MN",
  },
  {
    latitude: 57.54,
    longitude: -4.05,
    airportCode: "INV",
    airportName: "Inverness Intl Arpt",
    cityCode: "INV",
    cityName: "Inverness",
    countryCode: "GB",
    region: "Scotland",
  },
  {
    latitude: -3.7847,
    longitude: -73.30888,
    airportCode: "IQT",
    airportName: "Coronel FAP Francisco Secada Vignetta Intl Arpt",
    cityCode: "IQT",
    cityName: "Iquitos",
    countryCode: "PE",
    region: "Peru",
  },
  {
    latitude: 40.9769,
    longitude: 28.81472,
    airportCode: "IST",
    airportName: "Istanbul",
    cityCode: "IST",
    cityName: "Istanbul",
    countryCode: "TR",
    region: "Turkey",
  },
  {
    latitude: 42.4911,
    longitude: -76.46083,
    airportCode: "ITH",
    airportName: "Tompkins County",
    cityCode: "ITH",
    cityName: "Ithaca",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 19.7213,
    longitude: -155.04833,
    airportCode: "ITO",
    airportName: "Hilo International",
    cityCode: "ITO",
    cityName: "Hilo",
    countryCode: "US",
    region: "HI",
  },
  {
    latitude: 43.6072,
    longitude: -110.73777,
    airportCode: "JAC",
    airportName: "Jackson Hole",
    cityCode: "JAC",
    cityName: "Jackson Hole",
    countryCode: "US",
    region: "WY",
  },
  {
    latitude: 32.3111,
    longitude: -90.07583,
    airportCode: "JAN",
    airportName: "Jackson-evers",
    cityCode: "JAN",
    cityName: "Jackson",
    countryCode: "US",
    region: "MS",
  },
  {
    latitude: 30.4941,
    longitude: -81.68777,
    airportCode: "JAX",
    airportName: "Jacksonville Intl",
    cityCode: "JAX",
    cityName: "Jacksonville",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 40.6397,
    longitude: -73.77888,
    airportCode: "JFK",
    airportName: "John F Kennedy International",
    cityCode: "NYC",
    cityName: "New York-Kennedy",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 11.5472,
    longitude: 43.15944,
    airportCode: "JIB",
    airportName: "Ambouli",
    cityCode: "JIB",
    cityName: "Djibouti",
    countryCode: "DJ",
    region: "Djibouti",
  },
  {
    latitude: -26.1391,
    longitude: 28.24611,
    airportCode: "JNB",
    airportName: "Johannesburg Intl",
    cityCode: "JNB",
    cityName: "Johannesburg",
    countryCode: "ZA",
    region: "South Africa",
  },
  {
    latitude: 58.355,
    longitude: -134.57638,
    airportCode: "JNU",
    airportName: "Juneau Intl Arpt",
    cityCode: "JNU",
    cityName: "Juneau",
    countryCode: "US",
    region: "AK",
  },
  {
    latitude: -3.4294,
    longitude: 37.07444,
    airportCode: "JRO",
    airportName: "Kilimanjaro",
    cityCode: "JRO",
    cityName: "Kilimanjaro",
    countryCode: "TZ",
    region: "Tanzania",
  },
  {
    latitude: 36.3991,
    longitude: 25.47944,
    airportCode: "JTR",
    airportName: "Santorini (Thira)",
    cityCode: "JTR",
    cityName: "Santorini",
    countryCode: "GR",
    region: "Greece",
  },
  {
    latitude: 50.345,
    longitude: 30.89472,
    airportCode: "KBP",
    airportName: "Boryspil Intl Arpt",
    cityCode: "KBP",
    cityName: "Kiev-Boryspil",
    countryCode: "UA",
    region: "Ukraine",
  },
  {
    latitude: 63.985,
    longitude: -22.60555,
    airportCode: "KEF",
    airportName: "Keflavik",
    cityCode: "KEF",
    cityName: "Reykjavik",
    countryCode: "IS",
    region: "Iceland",
  },
  {
    latitude: -1.9686,
    longitude: 30.13944,
    airportCode: "KGL",
    airportName: "Kigalin International",
    cityCode: "KGL",
    cityName: "Kigali",
    countryCode: "RW",
    region: "Rwanda",
  },
  {
    latitude: 28.8638,
    longitude: 115.90222,
    airportCode: "KHN",
    airportName: "Nanchang Changbei Intl Arpt",
    cityCode: "KHN",
    cityName: "Nanchang",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 37.9558,
    longitude: 139.12083,
    airportCode: "KIJ",
    airportName: "Niigata",
    cityCode: "KIJ",
    cityName: "Niigata",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 17.9355,
    longitude: -76.7875,
    airportCode: "KIN",
    airportName: "Norman Manley Arpt",
    cityCode: "KIN",
    cityName: "Kingston",
    countryCode: "JM",
    region: "Jamaica",
  },
  {
    latitude: -0.0855,
    longitude: 34.72972,
    airportCode: "KIS",
    airportName: "Kisumu Intl Airport",
    cityCode: "KIS",
    cityName: "Kisumu",
    countryCode: "KE",
    region: "KE",
  },
  {
    latitude: 34.4272,
    longitude: 135.24416,
    airportCode: "KIX",
    airportName: "Kansai Intl Arpt",
    cityCode: "OSA",
    cityName: "Osaka-Kansai",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 24.9922,
    longitude: 102.74361,
    airportCode: "KMG",
    airportName: "Kunming",
    cityCode: "KMG",
    cityName: "Kunming",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 36.3947,
    longitude: 136.40666,
    airportCode: "KMQ",
    airportName: "Komatsu",
    cityCode: "KMQ",
    cityName: "Komatsu",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 19.7388,
    longitude: -156.04555,
    airportCode: "KOA",
    airportName: "Kona International Airport",
    cityCode: "KOA",
    cityName: "Kona",
    countryCode: "US",
    region: "HI",
  },
  {
    latitude: 31.8033,
    longitude: 130.71944,
    airportCode: "KOJ",
    airportName: "Kagoshima",
    cityCode: "KOJ",
    cityName: "Kagoshima",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 50.0777,
    longitude: 19.78472,
    airportCode: "KRK",
    airportName: "John Paul II Balice Intl",
    cityCode: "KRK",
    cityName: "Krakow",
    countryCode: "PL",
    region: "Poland",
  },
  {
    latitude: 58.2041,
    longitude: 8.08527,
    airportCode: "KRS",
    airportName: "Kjevik Airport",
    cityCode: "KRS",
    cityName: "Kristiansand",
    countryCode: "NO",
    region: "Norway",
  },
  {
    latitude: 55.3555,
    longitude: -131.71388,
    airportCode: "KTN",
    airportName: "Ketchikan International Airport",
    cityCode: "KTN",
    cityName: "Ketchikan",
    countryCode: "US",
    region: "AK",
  },
  {
    latitude: 2.7455,
    longitude: 101.71472,
    airportCode: "KUL",
    airportName: "Kuala Lumpur Intl Arpt",
    cityCode: "KUL",
    cityName: "Kuala Lumpur",
    countryCode: "MY",
    region: "Malaysia",
  },
  {
    latitude: 26.5386,
    longitude: 106.80083,
    airportCode: "KWE",
    airportName: "Guiyang",
    cityCode: "KWE",
    cityName: "Guiyang",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 29.2266,
    longitude: 47.96888,
    airportCode: "KWI",
    airportName: "Kuwait International",
    cityCode: "KWI",
    cityName: "Kuwait",
    countryCode: "KW",
    region: "Kuwait",
  },
  {
    latitude: 25.218,
    longitude: 110.03916,
    airportCode: "KWL",
    airportName: "Guilin Liangjiang Intl Arpt",
    cityCode: "KWL",
    cityName: "Guilin",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 42.7786,
    longitude: -84.58722,
    airportCode: "LAN",
    airportName: "Capital City",
    cityCode: "LAN",
    cityName: "Lansing",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 36.08,
    longitude: -115.15222,
    airportCode: "LAS",
    airportName: "Mc Carran Intl",
    cityCode: "LAS",
    cityName: "Las Vegas",
    countryCode: "US",
    region: "NV",
  },
  {
    latitude: 33.9425,
    longitude: -118.40805,
    airportCode: "LAX",
    airportName: "Los Angeles Intl",
    cityCode: "LAX",
    cityName: "Los Angeles",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 53.8658,
    longitude: -1.66055,
    airportCode: "LBA",
    airportName: "Leeds/Bradford Airport",
    cityCode: "LBA",
    cityName: "Leeds",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 0.4586,
    longitude: 9.41222,
    airportCode: "LBV",
    airportName: "Libreville",
    cityCode: "LBV",
    cityName: "Libreville",
    countryCode: "GA",
    region: "Gabon",
  },
  {
    latitude: 34.875,
    longitude: 33.62472,
    airportCode: "LCA",
    airportName: "Larnaca",
    cityCode: "LCA",
    cityName: "Larnaca",
    countryCode: "CY",
    region: "Cyprus",
  },
  {
    latitude: 43.3022,
    longitude: -8.37722,
    airportCode: "LCG",
    airportName: "A Coruna Arpt",
    cityCode: "LCG",
    cityName: "A Coruna",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 51.505,
    longitude: 0.05416,
    airportCode: "LCY",
    airportName: "London City Airport",
    cityCode: "LCY",
    cityName: "London City",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: -23.3336,
    longitude: -51.13,
    airportCode: "LDB",
    airportName: "Londrina",
    cityCode: "LDB",
    cityName: "Londrina",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 59.8002,
    longitude: 30.2625,
    airportCode: "LED",
    airportName: "Pulkovo Airport",
    cityCode: "LED",
    cityName: "St Petersburg",
    countryCode: "RU",
    region: "Russia",
  },
  {
    latitude: 38.0363,
    longitude: -84.60583,
    airportCode: "LEX",
    airportName: "Blue Grass",
    cityCode: "LEX",
    cityName: "Lexington",
    countryCode: "US",
    region: "KY",
  },
  {
    latitude: 30.2052,
    longitude: -91.9875,
    airportCode: "LFT",
    airportName: "Lafayette Regl",
    cityCode: "LFT",
    cityName: "Lafayette",
    countryCode: "US",
    region: "LA",
  },
  {
    latitude: 6.1655,
    longitude: 1.25444,
    airportCode: "LFW",
    airportName: "Lome",
    cityCode: "LFW",
    cityName: "Lome",
    countryCode: "TG",
    region: "Togo",
  },
  {
    latitude: 40.7772,
    longitude: -73.8725,
    airportCode: "LGA",
    airportName: "LaGuardia",
    cityCode: "NYC",
    cityName: "New York-LaGuardia",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 33.8177,
    longitude: -118.15166,
    airportCode: "LGB",
    airportName: "Long Beach",
    cityCode: "LGB",
    cityName: "Long Beach",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 51.4775,
    longitude: -0.46138,
    airportCode: "LHR",
    airportName: "Heathrow Airport",
    cityCode: "LON",
    cityName: "London-Heathrow",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 36.5166,
    longitude: 103.61666,
    airportCode: "LHW",
    airportName: "Lanzhou Airport",
    cityCode: "LHW",
    cityName: "Lanzhou",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 21.9763,
    longitude: -159.33611,
    airportCode: "LIH",
    airportName: "Lihue",
    cityCode: "LIH",
    cityName: "Lihue",
    countryCode: "US",
    region: "HI",
  },
  {
    latitude: -12.0219,
    longitude: -77.11444,
    airportCode: "LIM",
    airportName: "Jorge Chavez International",
    cityCode: "LIM",
    cityName: "Lima",
    countryCode: "PE",
    region: "Peru",
  },
  {
    latitude: 45.4494,
    longitude: 9.27833,
    airportCode: "LIN",
    airportName: "Linate Airport",
    cityCode: "LIN",
    cityName: "Milan-Linate",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 10.5933,
    longitude: -85.54444,
    airportCode: "LIR",
    airportName: "Daniel Oduber International",
    cityCode: "LIR",
    cityName: "Guanacaste Liberia",
    countryCode: "CR",
    region: "Costa Rica",
  },
  {
    latitude: 38.7741,
    longitude: -9.13416,
    airportCode: "LIS",
    airportName: "Portela",
    cityCode: "LIS",
    cityName: "Lisbon",
    countryCode: "PT",
    region: "Portugal",
  },
  {
    latitude: 34.7294,
    longitude: -92.22444,
    airportCode: "LIT",
    airportName: "Bill and Hillary Clinton National Airport",
    cityCode: "LIT",
    cityName: "Little Rock",
    countryCode: "US",
    region: "AR",
  },
  {
    latitude: 46.2236,
    longitude: 14.4575,
    airportCode: "LJU",
    airportName: "Brnik",
    cityCode: "LJU",
    cityName: "Ljubljana",
    countryCode: "SI",
    region: "Slovenia",
  },
  {
    latitude: -13.7894,
    longitude: 33.78111,
    airportCode: "LLW",
    airportName: "Kumuzu International Airport",
    cityCode: "LLW",
    cityName: "Lilongwe",
    countryCode: "MW",
    region: "Malawi",
  },
  {
    latitude: 40.8511,
    longitude: -96.75916,
    airportCode: "LNK",
    airportName: "Lincoln",
    cityCode: "LNK",
    cityName: "Lincoln",
    countryCode: "US",
    region: "NE",
  },
  {
    latitude: 6.5775,
    longitude: 3.32111,
    airportCode: "LOS",
    airportName: "Murtala Muhammed",
    cityCode: "LOS",
    cityName: "Lagos",
    countryCode: "NG",
    region: "Nigeria",
  },
  {
    latitude: 27.9319,
    longitude: -15.38666,
    airportCode: "LPA",
    airportName: "Gran Canaria",
    cityCode: "LPA",
    cityName: "Las Palmas",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 58.4022,
    longitude: 15.52555,
    airportCode: "LPI",
    airportName: "Linkoping City Airport",
    cityCode: "LPI",
    cityName: "Linkoping",
    countryCode: "SE",
    region: "Sweden",
  },
  {
    latitude: 43.8788,
    longitude: -91.25666,
    airportCode: "LSE",
    airportName: "La Crosse Municipal Arpt",
    cityCode: "LSE",
    cityName: "La Crosse",
    countryCode: "US",
    region: "WI",
  },
  {
    latitude: 49.6233,
    longitude: 6.20444,
    airportCode: "LUX",
    airportName: "Findel",
    cityCode: "LUX",
    cityName: "Luxembourg",
    countryCode: "LU",
    region: "Luxembourg",
  },
  {
    latitude: 46.3755,
    longitude: -117.01361,
    airportCode: "LWS",
    airportName: "Nez Perce County Rgnl",
    cityCode: "LWS",
    cityName: "Lewiston",
    countryCode: "US",
    region: "ID",
  },
  {
    latitude: 45.7255,
    longitude: 5.08111,
    airportCode: "LYS",
    airportName: "Lyon-Saint Exupery Arpt",
    cityCode: "LYS",
    cityName: "Lyon",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 12.9944,
    longitude: 80.18055,
    airportCode: "MAA",
    airportName: "Chennai Intl Arpt",
    cityCode: "MAA",
    cityName: "Chennai",
    countryCode: "IN",
    region: "India",
  },
  {
    latitude: 40.4936,
    longitude: -3.56666,
    airportCode: "MAD",
    airportName: "Barajas Airport",
    cityCode: "MAD",
    cityName: "Madrid",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 53.3536,
    longitude: -2.275,
    airportCode: "MAN",
    airportName: "Ringway International Airport",
    cityCode: "MAN",
    cityName: "Manchester",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: -3.0386,
    longitude: -60.04972,
    airportCode: "MAO",
    airportName: "Eduardo Gomes Intl Arpt",
    cityCode: "MAO",
    cityName: "Manaus",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: -4.028,
    longitude: 39.59972,
    airportCode: "MBA",
    airportName: "Moi International",
    cityCode: "MBA",
    cityName: "Mombasa",
    countryCode: "KE",
    region: "Kenya",
  },
  {
    latitude: 18.5036,
    longitude: -77.91333,
    airportCode: "MBJ",
    airportName: "Sangster Intl Arpt",
    cityCode: "MBJ",
    cityName: "Montego Bay",
    countryCode: "JM",
    region: "Jamaica",
  },
  {
    latitude: 43.5319,
    longitude: -84.0825,
    airportCode: "MBS",
    airportName: "Tri-City Airport",
    cityCode: "MBS",
    cityName: "Bay City",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 39.2975,
    longitude: -94.71388,
    airportCode: "MCI",
    airportName: "Kansas City Intl",
    cityCode: "MKC",
    cityName: "Kansas City",
    countryCode: "US",
    region: "MO",
  },
  {
    latitude: 28.4294,
    longitude: -81.30888,
    airportCode: "MCO",
    airportName: "Orlando Intl",
    cityCode: "ORL",
    cityName: "Orlando",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 23.5927,
    longitude: 58.28166,
    airportCode: "MCT",
    airportName: "Seeb Airport",
    cityCode: "MCT",
    cityName: "Muscat",
    countryCode: "OM",
    region: "Oman",
  },
  {
    latitude: -9.5108,
    longitude: -35.79166,
    airportCode: "MCZ",
    airportName: "Palmares",
    cityCode: "MCZ",
    cityName: "Maceio",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 6.1644,
    longitude: -75.42305,
    airportCode: "MDE",
    airportName: "Jose Maria Cordova",
    cityCode: "MDE",
    cityName: "Medellin",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 40.1936,
    longitude: -76.76333,
    airportCode: "MDT",
    airportName: "Harrisburg Intl",
    cityCode: "MDT",
    cityName: "Harrisburg",
    countryCode: "US",
    region: "PA",
  },
  {
    latitude: 41.7861,
    longitude: -87.7525,
    airportCode: "MDW",
    airportName: "Chicago-Midway",
    cityCode: "CHI",
    cityName: "Chicago-Midway",
    countryCode: "US",
    region: "IL",
  },
  {
    latitude: -32.8316,
    longitude: -68.79277,
    airportCode: "MDZ",
    airportName: "Governor Francisco Gabrielli Intl Arpt",
    cityCode: "MDZ",
    cityName: "Mendoza",
    countryCode: "AR",
    region: "Argentina",
  },
  {
    latitude: -37.6733,
    longitude: 144.84333,
    airportCode: "MEL",
    airportName: "Melbourne",
    cityCode: "MEL",
    cityName: "Melbourne",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 35.0425,
    longitude: -89.97666,
    airportCode: "MEM",
    airportName: "Memphis Intl",
    cityCode: "MEM",
    cityName: "Memphis",
    countryCode: "US",
    region: "TN",
  },
  {
    latitude: 19.4363,
    longitude: -99.07222,
    airportCode: "MEX",
    airportName: "Benito Juarez Intl Arpt",
    cityCode: "MEX",
    cityName: "Mexico City",
    countryCode: "MX",
    region: "Mexico",
  },
  {
    latitude: 42.3741,
    longitude: -122.87361,
    airportCode: "MFR",
    airportName: "Medford",
    cityCode: "MFR",
    cityName: "Medford",
    countryCode: "US",
    region: "OR",
  },
  {
    latitude: -23.4761,
    longitude: -52.01583,
    airportCode: "MGF",
    airportName: "Regional De Maringa SBMG",
    cityCode: "MGF",
    cityName: "Maringa",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 32.3005,
    longitude: -86.39388,
    airportCode: "MGM",
    airportName: "Dannelly",
    cityCode: "MGM",
    cityName: "Montgomery",
    countryCode: "US",
    region: "AL",
  },
  {
    latitude: 25.7933,
    longitude: -80.29055,
    airportCode: "MIA",
    airportName: "Miami Intl",
    cityCode: "MIA",
    cityName: "Miami",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 42.9472,
    longitude: -87.89666,
    airportCode: "MKE",
    airportName: "Gen Mitchell Intl",
    cityCode: "MKE",
    cityName: "Milwaukee",
    countryCode: "US",
    region: "WI",
  },
  {
    latitude: 35.8575,
    longitude: 14.4775,
    airportCode: "MLA",
    airportName: "Malta International",
    cityCode: "MLA",
    cityName: "Malta",
    countryCode: "MT",
    region: "Malta",
  },
  {
    latitude: 28.1027,
    longitude: -80.64527,
    airportCode: "MLB",
    airportName: "Melbourne Intl",
    cityCode: "MLB",
    cityName: "Melbourne",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 4.1916,
    longitude: 73.52916,
    airportCode: "MLE",
    airportName: "Male International",
    cityCode: "MLE",
    cityName: "Male",
    countryCode: "MV",
    region: "Maldives",
  },
  {
    latitude: 41.4486,
    longitude: -90.5075,
    airportCode: "MLI",
    airportName: "Quad Cities Airport",
    cityCode: "MLI",
    cityName: "Moline-Quad Cities",
    countryCode: "US",
    region: "IL",
  },
  {
    latitude: 32.5108,
    longitude: -92.03777,
    airportCode: "MLU",
    airportName: "Monroe Regl",
    cityCode: "MLU",
    cityName: "Monroe",
    countryCode: "US",
    region: "LA",
  },
  {
    latitude: 54.5091,
    longitude: -1.42944,
    airportCode: "MME",
    airportName: "Durham Tees Valley",
    cityCode: "MME",
    cityName: "Durham Tees Valley",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 30.6911,
    longitude: -88.24277,
    airportCode: "MOB",
    airportName: "Mobile Regl",
    cityCode: "MOB",
    cityName: "Mobile",
    countryCode: "US",
    region: "AL",
  },
  {
    latitude: 48.2594,
    longitude: -101.28027,
    airportCode: "MOT",
    airportName: "Minot International",
    cityCode: "MOT",
    cityName: "Minot",
    countryCode: "US",
    region: "ND",
  },
  {
    latitude: 43.5833,
    longitude: 3.96138,
    airportCode: "MPL",
    airportName: "Montpellier Miditerranie",
    cityCode: "MPL",
    cityName: "Montpellier",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 46.3536,
    longitude: -87.39527,
    airportCode: "MQT",
    airportName: "Sawyer International",
    cityCode: "MQT",
    cityName: "Marquette",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 43.4366,
    longitude: 5.215,
    airportCode: "MRS",
    airportName: "Marseille Provence Airport",
    cityCode: "MRS",
    cityName: "Marseille",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 43.1397,
    longitude: -89.3375,
    airportCode: "MSN",
    airportName: "Dane County Regional",
    cityCode: "MSN",
    cityName: "Madison",
    countryCode: "US",
    region: "WI",
  },
  {
    latitude: 46.9163,
    longitude: -114.09055,
    airportCode: "MSO",
    airportName: "Missoula Intl",
    cityCode: "MSO",
    cityName: "Missoula",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: 44.8819,
    longitude: -93.22166,
    airportCode: "MSP",
    airportName: "Minneapolis-St Paul Intl",
    cityCode: "MSP",
    cityName: "Minneapolis/St Paul",
    countryCode: "US",
    region: "MN",
  },
  {
    latitude: 29.9933,
    longitude: -90.25805,
    airportCode: "MSY",
    airportName: "Louis Armstrong New Orleans Intl Arpt",
    cityCode: "MSY",
    cityName: "New Orleans",
    countryCode: "US",
    region: "LA",
  },
  {
    latitude: 25.7786,
    longitude: -100.10694,
    airportCode: "MTY",
    airportName: "Gen Mariano Escobedo",
    cityCode: "MTY",
    cityName: "Monterrey",
    countryCode: "MX",
    region: "Mexico",
  },
  {
    latitude: 48.3538,
    longitude: 11.78611,
    airportCode: "MUC",
    airportName: "Franz Josef Strauss",
    cityCode: "MUC",
    cityName: "Munich",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: -34.8383,
    longitude: -56.03083,
    airportCode: "MVD",
    airportName: "Carrasco International",
    cityCode: "MVD",
    cityName: "Montevideo",
    countryCode: "UY",
    region: "Uruguay",
  },
  {
    latitude: 41.3933,
    longitude: -70.6125,
    airportCode: "MVY",
    airportName: "Marthas Vineyard Airport",
    cityCode: "MVY",
    cityName: "Marthas Vineyard",
    countryCode: "US",
    region: "MA",
  },
  {
    latitude: 45.63,
    longitude: 8.72305,
    airportCode: "MXP",
    airportName: "Malpensa Arpt",
    cityCode: "MIL",
    cityName: "Milan-Malpensa",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 33.6797,
    longitude: -78.92833,
    airportCode: "MYR",
    airportName: "Myrtle Beach Intl",
    cityCode: "MYR",
    cityName: "Myrtle Beach",
    countryCode: "US",
    region: "SC",
  },
  {
    latitude: 40.8844,
    longitude: 14.29083,
    airportCode: "NAP",
    airportName: "Capodichino",
    cityCode: "NAP",
    cityName: "Naples",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 25.0388,
    longitude: -77.46611,
    airportCode: "NAS",
    airportName: "Nassau Intl Arpt",
    cityCode: "NAS",
    cityName: "Nassau",
    countryCode: "BS",
    region: "Bahamas",
  },
  {
    latitude: -5.768,
    longitude: -35.37611,
    airportCode: "NAT",
    airportName: "Augusto Severo",
    cityCode: "NAT",
    cityName: "Natal",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: -1.3191,
    longitude: 36.92777,
    airportCode: "NBO",
    airportName: "Jomo Kenyatta Intl Arpt",
    cityCode: "NBO",
    cityName: "Nairobi",
    countryCode: "KE",
    region: "Kenya",
  },
  {
    latitude: 43.6652,
    longitude: 7.215,
    airportCode: "NCE",
    airportName: "Cote Dazur International Airport",
    cityCode: "NCE",
    cityName: "Nice",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 55.0375,
    longitude: -1.69166,
    airportCode: "NCL",
    airportName: "Newcastle Airport",
    cityCode: "NCL",
    cityName: "Newcastle",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 12.1336,
    longitude: 15.03388,
    airportCode: "NDJ",
    airportName: "Ndjamena",
    cityCode: "NDJ",
    cityName: "Ndjamena",
    countryCode: "TD",
    region: "Chad",
  },
  {
    latitude: 29.8266,
    longitude: 121.46194,
    airportCode: "NGB",
    airportName: "Ningbo Lishe Intl Arpt",
    cityCode: "NGB",
    cityName: "Ningbo",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 34.8583,
    longitude: 136.80527,
    airportCode: "NGO",
    airportName: "Chu-Bu Centrair International (Central J",
    cityCode: "NGO",
    cityName: "Nagoya",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 13.4811,
    longitude: 2.17666,
    airportCode: "NIM",
    airportName: "Niamey",
    cityCode: "NIM",
    cityName: "Niamey",
    countryCode: "NE",
    region: "Niger",
  },
  {
    latitude: 18.0977,
    longitude: -15.94805,
    airportCode: "NKC",
    airportName: "Nouakchott",
    cityCode: "NKC",
    cityName: "Nouakchott",
    countryCode: "MR",
    region: "Mauritania",
  },
  {
    latitude: 22.6083,
    longitude: 108.1725,
    airportCode: "NNG",
    airportName: "Nanning",
    cityCode: "NNG",
    cityName: "Nanning",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 35.7647,
    longitude: 140.38638,
    airportCode: "NRT",
    airportName: "Narita International Airport",
    cityCode: "TYO",
    cityName: "Tokyo-Narita",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 3.7225,
    longitude: 11.55333,
    airportCode: "NSI",
    airportName: "Nsimalen Intl Arpt",
    cityCode: "NSI",
    cityName: "Yaounde",
    countryCode: "CM",
    region: "Cameroon",
  },
  {
    latitude: 47.1569,
    longitude: -1.60777,
    airportCode: "NTE",
    airportName: "Nantes Atlantique",
    cityCode: "NTE",
    cityName: "Nantes",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 49.4986,
    longitude: 11.06694,
    airportCode: "NUE",
    airportName: "Nuremberg",
    cityCode: "NUE",
    cityName: "Nuremberg",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: -26.88,
    longitude: -48.65138,
    airportCode: "NVT",
    airportName: "Navegantes",
    cityCode: "NVT",
    cityName: "Navegantes",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 52.6758,
    longitude: 1.28277,
    airportCode: "NWI",
    airportName: "Norwich International Airport",
    cityCode: "NWI",
    cityName: "Norwich",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 34.7083,
    longitude: -77.43972,
    airportCode: "OAJ",
    airportName: "Albert J Ellis",
    cityCode: "OAJ",
    cityName: "Jacksonville",
    countryCode: "US",
    region: "NC",
  },
  {
    latitude: 37.7213,
    longitude: -122.22083,
    airportCode: "OAK",
    airportName: "Metro Oakland Intl",
    cityCode: "OAK",
    cityName: "Oakland",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 20.8986,
    longitude: -156.43055,
    airportCode: "OGG",
    airportName: "Kahului",
    cityCode: "OGG",
    cityName: "Kahului",
    countryCode: "US",
    region: "HI",
  },
  {
    latitude: 26.1958,
    longitude: 127.64583,
    airportCode: "OKA",
    airportName: "Naha Arpt",
    cityCode: "OKA",
    cityName: "Okinawa",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 35.393,
    longitude: -97.60083,
    airportCode: "OKC",
    airportName: "Will Rogers World",
    cityCode: "OKC",
    cityName: "Oklahoma City",
    countryCode: "US",
    region: "OK",
  },
  {
    latitude: 34.7569,
    longitude: 133.85527,
    airportCode: "OKJ",
    airportName: "Okayama",
    cityCode: "OKJ",
    cityName: "Okayama",
    countryCode: "JP",
    region: "Japan",
  },
  {
    latitude: 40.8986,
    longitude: 9.51777,
    airportCode: "OLB",
    airportName: "Olbia - Costa Smeralda Intl Arpt",
    cityCode: "OLB",
    cityName: "Olbia",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 41.303,
    longitude: -95.89416,
    airportCode: "OMA",
    airportName: "Eppley",
    cityCode: "OMA",
    cityName: "Omaha",
    countryCode: "US",
    region: "NE",
  },
  {
    latitude: 34.0561,
    longitude: -117.60111,
    airportCode: "ONT",
    airportName: "Ontario Intl",
    cityCode: "ONT",
    cityName: "Ontario",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: -28.1644,
    longitude: 153.50472,
    airportCode: "OOL",
    airportName: "Gold Coast Arpt",
    cityCode: "OOL",
    cityName: "Gold Coast",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 41.2355,
    longitude: -8.67805,
    airportCode: "OPO",
    airportName: "Francisco de Sa Carneiro Arpt",
    cityCode: "OPO",
    cityName: "Porto",
    countryCode: "PT",
    region: "Portugal",
  },
  {
    latitude: 41.9786,
    longitude: -87.90472,
    airportCode: "ORD",
    airportName: "O Hare International Airport",
    cityCode: "CHI",
    cityName: "Chicago-Ohare",
    countryCode: "US",
    region: "IL",
  },
  {
    latitude: 36.8947,
    longitude: -76.20111,
    airportCode: "ORF",
    airportName: "Norfolk Intl",
    cityCode: "ORF",
    cityName: "Norfolk",
    countryCode: "US",
    region: "VA",
  },
  {
    latitude: 51.8413,
    longitude: -8.49111,
    airportCode: "ORK",
    airportName: "Cork Arpt",
    cityCode: "ORK",
    cityName: "Cork",
    countryCode: "IE",
    region: "Ireland",
  },
  {
    latitude: 48.7233,
    longitude: 2.37944,
    airportCode: "ORY",
    airportName: "Orly Field",
    cityCode: "ORY",
    cityName: "Paris-Orly",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 60.1938,
    longitude: 11.10027,
    airportCode: "OSL",
    airportName: "Oslo (Gardermoen)",
    cityCode: "OSL",
    cityName: "Oslo",
    countryCode: "NO",
    region: "Norway",
  },
  {
    latitude: 44.5722,
    longitude: 26.10222,
    airportCode: "OTP",
    airportName: "Otopeni Intl",
    cityCode: "BUH",
    cityName: "Bucharest",
    countryCode: "RO",
    region: "Romania",
  },
  {
    latitude: 12.3533,
    longitude: -1.5125,
    airportCode: "OUA",
    airportName: "Ouagadougou",
    cityCode: "OUA",
    cityName: "Ouagadougou",
    countryCode: "BF",
    region: "Burkina Faso",
  },
  {
    latitude: 18.58,
    longitude: -72.2925,
    airportCode: "PAP",
    airportName: "Toussaint Louverture Intl Arpt",
    cityCode: "PAP",
    cityName: "Port-au-Prince",
    countryCode: "HT",
    region: "Haiti",
  },
  {
    latitude: 26.683,
    longitude: -80.09555,
    airportCode: "PBI",
    airportName: "Palm Beach Intl",
    cityCode: "PBI",
    cityName: "West Palm Beach",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 45.5886,
    longitude: -122.5975,
    airportCode: "PDX",
    airportName: "Portland Intl",
    cityCode: "PDX",
    cityName: "Portland",
    countryCode: "US",
    region: "OR",
  },
  {
    latitude: 4.8127,
    longitude: -75.73944,
    airportCode: "PEI",
    airportName: "Mateca�a International Airport",
    cityCode: "PEI",
    cityName: "Pereira",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 5.2972,
    longitude: 100.27666,
    airportCode: "PEN",
    airportName: "Penang International",
    cityCode: "PEN",
    cityName: "Penang",
    countryCode: "MY",
    region: "Malaysia",
  },
  {
    latitude: -31.9402,
    longitude: 115.96694,
    airportCode: "PER",
    airportName: "Perth International",
    cityCode: "PER",
    cityName: "Perth",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 39.8719,
    longitude: -75.24111,
    airportCode: "PHL",
    airportName: "Philadelphia Intl",
    cityCode: "PHL",
    cityName: "Philadelphia",
    countryCode: "US",
    region: "PA",
  },
  {
    latitude: 33.4341,
    longitude: -112.01166,
    airportCode: "PHX",
    airportName: "Sky Harbor Intl",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryCode: "US",
    region: "AZ",
  },
  {
    latitude: 42.9097,
    longitude: -112.59583,
    airportCode: "PIH",
    airportName: "Pocatello Regl",
    cityCode: "PIH",
    cityName: "Pocatello",
    countryCode: "US",
    region: "ID",
  },
  {
    latitude: 40.4913,
    longitude: -80.23277,
    airportCode: "PIT",
    airportName: "Pittsburgh Intl",
    cityCode: "PIT",
    cityName: "Pittsburgh",
    countryCode: "US",
    region: "PA",
  },
  {
    latitude: 39.51,
    longitude: 116.41,
    airportCode: "PKX",
    airportName: "Beijing Daxing Intl Arpt",
    cityCode: "PKX",
    cityName: "Beijing-Daxing",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 45.5708,
    longitude: -84.79666,
    airportCode: "PLN",
    airportName: "Emmet County",
    cityCode: "PLN",
    cityName: "Pellston",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 21.7736,
    longitude: -72.26583,
    airportCode: "PLS",
    airportName: "International",
    cityCode: "PLS",
    cityName: "Providenciales",
    countryCode: "TC",
    region: "Turks and Caicos",
  },
  {
    latitude: 39.5516,
    longitude: 2.73888,
    airportCode: "PMI",
    airportName: "Son Sant Joan Airport",
    cityCode: "PMI",
    cityName: "Palma de Mallorca/Majorca",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 38.1819,
    longitude: 13.09944,
    airportCode: "PMO",
    airportName: "Punta Raisi",
    cityCode: "PMO",
    cityName: "Palermo",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 11.5466,
    longitude: 104.84416,
    airportCode: "PNH",
    airportName: "Phnom Penh Intl Arpt",
    cityCode: "PNH",
    cityName: "Phnom Penh",
    countryCode: "KH",
    region: "Cambodia/Kampuchea",
  },
  {
    latitude: -4.8161,
    longitude: 11.88666,
    airportCode: "PNR",
    airportName: "Pointe Noire",
    cityCode: "PNR",
    cityName: "Pointe Noire",
    countryCode: "CG",
    region: "Congo",
  },
  {
    latitude: 30.4733,
    longitude: -87.18666,
    airportCode: "PNS",
    airportName: "Pensacola Regl",
    cityCode: "PNS",
    cityName: "Pensacola",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: -29.9944,
    longitude: -51.17138,
    airportCode: "POA",
    airportName: "Salgado Filho",
    cityCode: "POA",
    cityName: "Porto Alegre",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 52.4211,
    longitude: 16.82638,
    airportCode: "POZ",
    airportName: "Poznan Lawica Henryk Wieniawski Airport",
    cityCode: "POZ",
    cityName: "Poznan",
    countryCode: "PL",
    region: "Poland",
  },
  {
    latitude: -17.5538,
    longitude: -149.60722,
    airportCode: "PPT",
    airportName: "Faaa",
    cityCode: "PPT",
    cityName: "Papeete",
    countryCode: "PF",
    region: "French Polynesia",
  },
  {
    latitude: 50.1008,
    longitude: 14.26,
    airportCode: "PRG",
    airportName: "Prague - Ruzyne International",
    cityCode: "PRG",
    cityName: "Prague",
    countryCode: "CZ",
    region: "Czech Republic",
  },
  {
    latitude: 43.6827,
    longitude: 10.39555,
    airportCode: "PSA",
    airportName: "Galileo Galilei",
    cityCode: "PSA",
    cityName: "Pisa",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 46.2666,
    longitude: -119.11666,
    airportCode: "PSC",
    airportName: "Tri-Cities",
    cityCode: "PSC",
    cityName: "Pasco/Kennewick",
    countryCode: "US",
    region: "WA",
  },
  {
    latitude: 33.8297,
    longitude: -116.50666,
    airportCode: "PSP",
    airportName: "Palm Springs Regl",
    cityCode: "PSP",
    cityName: "Palm Springs",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 9.0713,
    longitude: -79.38333,
    airportCode: "PTY",
    airportName: "Tocumen Intl Arpt",
    cityCode: "PTY",
    cityName: "Panama City",
    countryCode: "PA",
    region: "Panama",
  },
  {
    latitude: 43.38,
    longitude: -0.41861,
    airportCode: "PUF",
    airportName: "Pau Pyrenees Airport",
    cityCode: "PUF",
    cityName: "Pau",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 18.5675,
    longitude: -68.36333,
    airportCode: "PUJ",
    airportName: "Punta Cana",
    cityCode: "PUJ",
    cityName: "Punta Cana",
    countryCode: "DO",
    region: "Dominican Republic",
  },
  {
    latitude: 35.1766,
    longitude: 128.9425,
    airportCode: "PUS",
    airportName: "Gimhae Intl Arpt",
    cityCode: "PUS",
    cityName: "Busan",
    countryCode: "KR",
    region: "South Korea",
  },
  {
    latitude: 41.725,
    longitude: -71.42583,
    airportCode: "PVD",
    airportName: "T.F. Green Airport",
    cityCode: "PVD",
    cityName: "Providence",
    countryCode: "US",
    region: "RI",
  },
  {
    latitude: 31.1433,
    longitude: 121.80527,
    airportCode: "PVG",
    airportName: "Pu Dong",
    cityCode: "PVG",
    cityName: "Shanghai-Pudong",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 20.68,
    longitude: -105.25416,
    airportCode: "PVR",
    airportName: "Licenciado Gustavo Dmaz Ordaz Internatio",
    cityCode: "PVR",
    cityName: "Puerto Vallarta",
    countryCode: "MX",
    region: "Mexico",
  },
  {
    latitude: 43.6461,
    longitude: -70.30916,
    airportCode: "PWM",
    airportName: "Portland Intl",
    cityCode: "PWM",
    cityName: "Portland",
    countryCode: "US",
    region: "ME",
  },
  {
    latitude: 20.6172,
    longitude: -100.18555,
    airportCode: "QRO",
    airportName: "Queretaro",
    cityCode: "QRO",
    cityName: "Queretaro",
    countryCode: "MX",
    region: "Mexico",
  },
  {
    latitude: 31.6069,
    longitude: -8.03638,
    airportCode: "RAK",
    airportName: "Menara",
    cityCode: "RAK",
    cityName: "Marrakech",
    countryCode: "MA",
    region: "Morocco",
  },
  {
    latitude: 44.043,
    longitude: -103.05694,
    airportCode: "RAP",
    airportName: "Rapid City Regl",
    cityCode: "RAP",
    cityName: "Rapid City",
    countryCode: "US",
    region: "SD",
  },
  {
    latitude: 34.0513,
    longitude: -6.75138,
    airportCode: "RBA",
    airportName: "Sale Arpt",
    cityCode: "RBA",
    cityName: "Rabat",
    countryCode: "MA",
    region: "Morocco",
  },
  {
    latitude: 44.2541,
    longitude: -121.15,
    airportCode: "RDM",
    airportName: "Roberts Field",
    cityCode: "RDM",
    cityName: "Redmond",
    countryCode: "US",
    region: "OR",
  },
  {
    latitude: 35.8777,
    longitude: -78.7875,
    airportCode: "RDU",
    airportName: "Raleigh-Durham Intl",
    cityCode: "RDU",
    cityName: "Raleigh/Durham",
    countryCode: "US",
    region: "NC",
  },
  {
    latitude: -8.1266,
    longitude: -34.92305,
    airportCode: "REC",
    airportName: "Guararapes Intl",
    cityCode: "REC",
    cityName: "Recife",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 45.6311,
    longitude: -89.4675,
    airportCode: "RHI",
    airportName: "Oneida County",
    cityCode: "RHI",
    cityName: "Rhinelander",
    countryCode: "US",
    region: "WI",
  },
  {
    latitude: 36.4055,
    longitude: 28.08611,
    airportCode: "RHO",
    airportName: "Diagoras",
    cityCode: "RHO",
    cityName: "Rhodes",
    countryCode: "GR",
    region: "Greece",
  },
  {
    latitude: 37.5052,
    longitude: -77.31972,
    airportCode: "RIC",
    airportName: "Richmond Intl",
    cityCode: "RIC",
    cityName: "Richmond",
    countryCode: "US",
    region: "VA",
  },
  {
    latitude: 39.4991,
    longitude: -119.76805,
    airportCode: "RNO",
    airportName: "Reno/Tahoe Intl",
    cityCode: "RNO",
    cityName: "Reno",
    countryCode: "US",
    region: "NV",
  },
  {
    latitude: 48.0719,
    longitude: -1.73222,
    airportCode: "RNS",
    airportName: "St Jacques",
    cityCode: "RNS",
    cityName: "Rennes",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 37.3255,
    longitude: -79.97555,
    airportCode: "ROA",
    airportName: "Roanoke Regl/Woodrum",
    cityCode: "ROA",
    cityName: "Roanoke",
    countryCode: "US",
    region: "VA",
  },
  {
    latitude: 6.2338,
    longitude: -10.36222,
    airportCode: "ROB",
    airportName: "Roberts Intl Airport",
    cityCode: "ROB",
    cityName: "Monrovia",
    countryCode: "LR",
    region: "Liberia",
  },
  {
    latitude: 43.1188,
    longitude: -77.6725,
    airportCode: "ROC",
    airportName: "Greater Rochester Intl",
    cityCode: "ROC",
    cityName: "Rochester",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: -32.9036,
    longitude: -60.78444,
    airportCode: "ROS",
    airportName: "Fisherton",
    cityCode: "ROS",
    cityName: "Rosario",
    countryCode: "AR",
    region: "Argentina",
  },
  {
    latitude: 43.9102,
    longitude: -92.49666,
    airportCode: "RST",
    airportName: "Rochester Municipal Arpt",
    cityCode: "RST",
    cityName: "Rochester",
    countryCode: "US",
    region: "MN",
  },
  {
    latitude: 26.5361,
    longitude: -81.75527,
    airportCode: "RSW",
    airportName: "Southwest Florida Intl",
    cityCode: "FMY",
    cityName: "Fort Myers",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 16.3169,
    longitude: -86.52305,
    airportCode: "RTB",
    airportName: "Roatan",
    cityCode: "RTB",
    cityName: "Roatan",
    countryCode: "HN",
    region: "Honduras",
  },
  {
    latitude: 24.9577,
    longitude: 46.69888,
    airportCode: "RUH",
    airportName: "King Khaled International",
    cityCode: "RUH",
    cityName: "Riyadh",
    countryCode: "SA",
    region: "Saudi Arabia",
  },
  {
    latitude: 13.4408,
    longitude: -89.05583,
    airportCode: "SAL",
    airportName: "El Salvador International",
    cityCode: "SAL",
    cityName: "San Salvador",
    countryCode: "SV",
    region: "El Salvador",
  },
  {
    latitude: 32.7336,
    longitude: -117.18972,
    airportCode: "SAN",
    airportName: "San Diego Intl-Lindbergh",
    cityCode: "SAN",
    cityName: "San Diego",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 15.4527,
    longitude: -87.92361,
    airportCode: "SAP",
    airportName: "Ramon Villeda Morales",
    cityCode: "SAP",
    cityName: "San Pedro Sula",
    countryCode: "HN",
    region: "Honduras",
  },
  {
    latitude: 29.5336,
    longitude: -98.46972,
    airportCode: "SAT",
    airportName: "San Antonio Intl",
    cityCode: "SAT",
    cityName: "San Antonio",
    countryCode: "US",
    region: "TX",
  },
  {
    latitude: 32.1275,
    longitude: -81.20222,
    airportCode: "SAV",
    airportName: "Savannah/Hilton Head Intl Airport",
    cityCode: "SAV",
    cityName: "Savannah",
    countryCode: "US",
    region: "GA",
  },
  {
    latitude: 41.7047,
    longitude: -86.31305,
    airportCode: "SBN",
    airportName: "South Bend Regional",
    cityCode: "SBN",
    cityName: "South Bend",
    countryCode: "US",
    region: "IN",
  },
  {
    latitude: 40.8505,
    longitude: -77.84722,
    airportCode: "SCE",
    airportName: "University Park Airport",
    cityCode: "SCE",
    cityName: "State College",
    countryCode: "US",
    region: "PA",
  },
  {
    latitude: -33.393,
    longitude: -70.78583,
    airportCode: "SCL",
    airportName: "Arturo Merino Benitez Intl Arpt",
    cityCode: "SCL",
    cityName: "Santiago",
    countryCode: "CL",
    region: "Chile",
  },
  {
    latitude: 38.1744,
    longitude: -85.73611,
    airportCode: "SDF",
    airportName: "Louisville Intl-Standiford",
    cityCode: "SDF",
    cityName: "Louisville",
    countryCode: "US",
    region: "KY",
  },
  {
    latitude: 18.4297,
    longitude: -69.66888,
    airportCode: "SDQ",
    airportName: "Las Americas Arpt",
    cityCode: "SDQ",
    cityName: "Santo Domingo",
    countryCode: "DO",
    region: "Dominican Republic",
  },
  {
    latitude: -22.9105,
    longitude: -43.16305,
    airportCode: "SDU",
    airportName: "Santos Dumont",
    cityCode: "SDU",
    cityName: "Rio de Janeiro",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 47.4488,
    longitude: -122.30944,
    airportCode: "SEA",
    airportName: "Seattle-Tacoma Intl",
    cityCode: "SEA",
    cityName: "Seattle",
    countryCode: "US",
    region: "WA",
  },
  {
    latitude: 37.6188,
    longitude: -122.39166,
    airportCode: "SFO",
    airportName: "San Francisco Intl",
    cityCode: "SFO",
    cityName: "San Francisco",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 37.2455,
    longitude: -93.38861,
    airportCode: "SGF",
    airportName: "Springfield-Branson Rg",
    cityCode: "SGF",
    cityName: "Springfield",
    countryCode: "US",
    region: "MO",
  },
  {
    latitude: 10.8188,
    longitude: 106.65194,
    airportCode: "SGN",
    airportName: "Tan Son Nhat Airport",
    cityCode: "SGN",
    cityName: "Ho Chi Minh City",
    countryCode: "VN",
    region: "Vietnam",
  },
  {
    latitude: 37.0905,
    longitude: -113.59305,
    airportCode: "SGU",
    airportName: "St George Mun",
    cityCode: "SGU",
    cityName: "St. George",
    countryCode: "US",
    region: "UT",
  },
  {
    latitude: 41.6397,
    longitude: 123.48333,
    airportCode: "SHE",
    airportName: "Shenyang",
    cityCode: "SHE",
    cityName: "Shenyang",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 32.4472,
    longitude: -93.82888,
    airportCode: "SHV",
    airportName: "Shreveport Regl",
    cityCode: "SHV",
    cityName: "Shreveport",
    countryCode: "US",
    region: "LA",
  },
  {
    latitude: 1.3591,
    longitude: 103.98944,
    airportCode: "SIN",
    airportName: "Changi Intl Airport",
    cityCode: "SIN",
    cityName: "Singapore",
    countryCode: "SG",
    region: "Singapore",
  },
  {
    latitude: 57.0472,
    longitude: -135.36166,
    airportCode: "SIT",
    airportName: "Sitka",
    cityCode: "SIT",
    cityName: "Sitka",
    countryCode: "US",
    region: "AK",
  },
  {
    latitude: 37.3625,
    longitude: -121.92888,
    airportCode: "SJC",
    airportName: "Norman Y Mineta San Jose Intl Arpt",
    cityCode: "SJC",
    cityName: "San Jose",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 23.1519,
    longitude: -109.72111,
    airportCode: "SJD",
    airportName: "Los Cabos",
    cityCode: "SJD",
    cityName: "Los Cabos",
    countryCode: "MX",
    region: "Mexico",
  },
  {
    latitude: 9.9938,
    longitude: -84.20888,
    airportCode: "SJO",
    airportName: "Juan Santamaria Airport",
    cityCode: "SJO",
    cityName: "San Jose",
    countryCode: "CR",
    region: "Costa Rica",
  },
  {
    latitude: 18.4394,
    longitude: -66.00194,
    airportCode: "SJU",
    airportName: "Luis Munoz Marin Intl Arpt",
    cityCode: "SJU",
    cityName: "San Juan",
    countryCode: "US",
    region: "PR",
  },
  {
    latitude: 17.3111,
    longitude: -62.71861,
    airportCode: "SKB",
    airportName: "Robert L Brandshaw Airport",
    cityCode: "SKB",
    cityName: "St. Kitts",
    countryCode: "KN",
    region: "Saint Kitts and Nevis",
  },
  {
    latitude: 40.5197,
    longitude: 22.97083,
    airportCode: "SKG",
    airportName: "Makedonia",
    cityCode: "SKG",
    cityName: "Thessaloniki",
    countryCode: "GR",
    region: "Greece",
  },
  {
    latitude: 40.7883,
    longitude: -111.97777,
    airportCode: "SLC",
    airportName: "Salt Lake City Intl",
    cityCode: "SLC",
    cityName: "Salt Lake City",
    countryCode: "US",
    region: "UT",
  },
  {
    latitude: 38.6955,
    longitude: -121.59083,
    airportCode: "SMF",
    airportName: "Sacramento Intl",
    cityCode: "SMF",
    cityName: "Sacramento",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 11.1197,
    longitude: -74.23055,
    airportCode: "SMR",
    airportName: "Sim�n Bol�var Intl Arpt",
    cityCode: "SMR",
    cityName: "Santa Marta",
    countryCode: "CO",
    region: "Colombia",
  },
  {
    latitude: 33.6755,
    longitude: -117.86833,
    airportCode: "SNA",
    airportName: "John Wayne",
    cityCode: "SNA",
    cityName: "Orange County",
    countryCode: "US",
    region: "CA",
  },
  {
    latitude: 42.6952,
    longitude: 23.40611,
    airportCode: "SOF",
    airportName: "Sofia",
    cityCode: "SOF",
    cityName: "Sofia",
    countryCode: "BG",
    region: "Bulgaria",
  },
  {
    latitude: 50.9502,
    longitude: -1.35666,
    airportCode: "SOU",
    airportName: "Southampton Airport",
    cityCode: "SOU",
    cityName: "Southampton",
    countryCode: "GB",
    region: "United Kingdom",
  },
  {
    latitude: 43.5388,
    longitude: 16.29805,
    airportCode: "SPU",
    airportName: "Split",
    cityCode: "SPU",
    cityName: "Split",
    countryCode: "HR",
    region: "Croatia",
  },
  {
    latitude: 27.3955,
    longitude: -82.55444,
    airportCode: "SRQ",
    airportName: "Sarasota-Bradenton",
    cityCode: "SRQ",
    cityName: "Sarasota-Bradenton",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: -12.9111,
    longitude: -38.33111,
    airportCode: "SSA",
    airportName: "Luis Eduardo Magalhaes",
    cityCode: "SSA",
    cityName: "Salvador de Bahia",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 19.4061,
    longitude: -70.60472,
    airportCode: "STI",
    airportName: "Cibao International Airport",
    cityCode: "STI",
    cityName: "Santiago",
    countryCode: "DO",
    region: "Dominican Republic",
  },
  {
    latitude: 38.7486,
    longitude: -90.37,
    airportCode: "STL",
    airportName: "Lambert-St Louis Intl",
    cityCode: "STL",
    cityName: "St. Louis",
    countryCode: "US",
    region: "MO",
  },
  {
    latitude: 48.69,
    longitude: 9.22194,
    airportCode: "STR",
    airportName: "Stuttgart Echterdingen Arpt",
    cityCode: "STR",
    cityName: "Stuttgart",
    countryCode: "DE",
    region: "Germany",
  },
  {
    latitude: 18.3372,
    longitude: -64.97333,
    airportCode: "STT",
    airportName: "Cyril E King Arpt",
    cityCode: "STT",
    cityName: "St. Thomas",
    countryCode: "US",
    region: "VI",
  },
  {
    latitude: 17.7019,
    longitude: -64.79861,
    airportCode: "STX",
    airportName: "Henry E Rohlsen Arpt",
    cityCode: "STX",
    cityName: "St. Croix",
    countryCode: "US",
    region: "VI",
  },
  {
    latitude: 38.9083,
    longitude: 16.24166,
    airportCode: "SUF",
    airportName: "Lamezia Terme",
    cityCode: "SUF",
    cityName: "Lamezia Terme",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 43.5055,
    longitude: -114.29722,
    airportCode: "SUN",
    airportName: "Sun Valley",
    cityCode: "SUN",
    cityName: "Sun Valley",
    countryCode: "US",
    region: "ID",
  },
  {
    latitude: 58.8766,
    longitude: 5.63777,
    airportCode: "SVG",
    airportName: "Stavanger Airport, Sola",
    cityCode: "SVG",
    cityName: "Stavanger",
    countryCode: "NO",
    region: "Norway",
  },
  {
    latitude: 55.9727,
    longitude: 37.41472,
    airportCode: "SVO",
    airportName: "Sheremetyevo Arpt",
    cityCode: "MOW",
    cityName: "Moscow",
    countryCode: "RU",
    region: "Russia",
  },
  {
    latitude: 37.418,
    longitude: -5.89305,
    airportCode: "SVQ",
    airportName: "San Pablo Arpt",
    cityCode: "SVQ",
    cityName: "Sevilla",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 18.0408,
    longitude: -63.10888,
    airportCode: "SXM",
    airportName: "Princess Juliana International",
    cityCode: "SXM",
    cityName: "St. Maarten",
    countryCode: "SX",
    region: "Netherland Antilles",
  },
  {
    latitude: -33.9461,
    longitude: 151.17722,
    airportCode: "SYD",
    airportName: "Sydney",
    cityCode: "SYD",
    cityName: "Sydney",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 43.1111,
    longitude: -76.10638,
    airportCode: "SYR",
    airportName: "Syracuse Hancock Intl",
    cityCode: "SYR",
    cityName: "Syracuse",
    countryCode: "US",
    region: "NY",
  },
  {
    latitude: 18.3027,
    longitude: 109.41222,
    airportCode: "SYX",
    airportName: "Fenghuang International (Phoenix Interna",
    cityCode: "SYX",
    cityName: "Sanya",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 47.7933,
    longitude: 13.00444,
    airportCode: "SZG",
    airportName: "Salzburg Airport W. A. Mozart",
    cityCode: "SZG",
    cityName: "Salzburg",
    countryCode: "AT",
    region: "Austria",
  },
  {
    latitude: 22.6391,
    longitude: 113.81055,
    airportCode: "SZX",
    airportName: "Shenzhen",
    cityCode: "SZX",
    cityName: "Shenzhen",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 35.8941,
    longitude: 128.65888,
    airportCode: "TAE",
    airportName: "Daegu Intl Arpt",
    cityCode: "TAE",
    cityName: "Daegu",
    countryCode: "KR",
    region: "South Korea",
  },
  {
    latitude: 36.2661,
    longitude: 120.37444,
    airportCode: "TAO",
    airportName: "Qingdao Liuting Intl Arpt",
    cityCode: "TAO",
    cityName: "Qingdao",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 41.6691,
    longitude: 44.95472,
    airportCode: "TBS",
    airportName: "Lochini (Novo Alexeyevka)",
    cityCode: "TBS",
    cityName: "Tbilisi",
    countryCode: "GE",
    region: "Georgia",
  },
  {
    latitude: 28.4827,
    longitude: -16.34166,
    airportCode: "TFN",
    airportName: "Tenerife Norte Los Rodeos",
    cityCode: "TFN",
    cityName: "Tenerife - Los Rodeos",
    countryCode: "ES",
    region: "Spain-Canary Islands",
  },
  {
    latitude: 28.0444,
    longitude: -16.5725,
    airportCode: "TFS",
    airportName: "Tenerife Sur Arpt",
    cityCode: "TFS",
    cityName: "Tenerife",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 42.3594,
    longitude: 19.25194,
    airportCode: "TGD",
    airportName: "Podgorica Arpt",
    cityCode: "TGD",
    cityName: "Podgorica",
    countryCode: "ME",
    region: "Montenegro",
  },
  {
    latitude: 41.415,
    longitude: 19.72083,
    airportCode: "TIA",
    airportName: "Rinas Mother Teresa",
    cityCode: "TIA",
    cityName: "Tirana",
    countryCode: "AL",
    region: "Albania",
  },
  {
    latitude: 30.3916,
    longitude: -84.34972,
    airportCode: "TLH",
    airportName: "Tallahassee Regl",
    cityCode: "TLH",
    cityName: "Tallahassee",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 43.635,
    longitude: 1.36777,
    airportCode: "TLS",
    airportName: "Blagnac",
    cityCode: "TLS",
    cityName: "Toulouse",
    countryCode: "FR",
    region: "France",
  },
  {
    latitude: 32.0072,
    longitude: 34.88055,
    airportCode: "TLV",
    airportName: "Ben Gurion Intl",
    cityCode: "TLV",
    cityName: "Tel Aviv",
    countryCode: "IL",
    region: "Israel",
  },
  {
    latitude: 36.8572,
    longitude: 117.21611,
    airportCode: "TNA",
    airportName: "Jinan",
    cityCode: "TNA",
    cityName: "Jinan",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: -18.7969,
    longitude: 47.47888,
    airportCode: "TNR",
    airportName: "Antananarivo",
    cityCode: "TNR",
    cityName: "Antananarivo",
    countryCode: "MG",
    region: "Madagascar",
  },
  {
    latitude: 27.9755,
    longitude: -82.53333,
    airportCode: "TPA",
    airportName: "Tampa Intl",
    cityCode: "TPA",
    cityName: "Tampa",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 25.0777,
    longitude: 121.23277,
    airportCode: "TPE",
    airportName: "Taoyuan Intl Airport",
    cityCode: "TPE",
    cityName: "Taipei",
    countryCode: "TW",
    region: "Taiwan",
  },
  {
    latitude: 63.4575,
    longitude: 10.92416,
    airportCode: "TRD",
    airportName: "Vaernes Airport",
    cityCode: "TRD",
    cityName: "Trondheim",
    countryCode: "NO",
    region: "Norway",
  },
  {
    latitude: 59.1866,
    longitude: 10.25861,
    airportCode: "TRF",
    airportName: "Sandefjord",
    cityCode: "TRF",
    cityName: "Sandefjord",
    countryCode: "NO",
    region: "Norway",
  },
  {
    latitude: 36.4752,
    longitude: -82.4075,
    airportCode: "TRI",
    airportName: "Tri-Cities Regional Airport",
    cityCode: "TRI",
    cityName: "Tri City",
    countryCode: "US",
    region: "TN",
  },
  {
    latitude: 45.2025,
    longitude: 7.64944,
    airportCode: "TRN",
    airportName: "Sandro Pertini (caselle)",
    cityCode: "TRN",
    cityName: "Turin",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 45.8275,
    longitude: 13.47222,
    airportCode: "TRS",
    airportName: "Ronchi Dei Legionari",
    cityCode: "TRS",
    cityName: "Trieste",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: -8.0847,
    longitude: -79.10944,
    airportCode: "TRU",
    airportName: "Capit�n FAP Carlos Mart�nez de Pinillos Intl Arpt",
    cityCode: "TRU",
    cityName: "Trujillo",
    countryCode: "PE",
    region: "Peru",
  },
  {
    latitude: 39.1244,
    longitude: 117.34611,
    airportCode: "TSN",
    airportName: "Tianjin Binhai Intl Arpt",
    cityCode: "TSN",
    cityName: "Tianjin",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: -19.2525,
    longitude: 146.76527,
    airportCode: "TSV",
    airportName: "Townsville Intl Arpt",
    cityCode: "TSV",
    cityName: "Townsville",
    countryCode: "AU",
    region: "Australia",
  },
  {
    latitude: 36.1983,
    longitude: -95.88805,
    airportCode: "TUL",
    airportName: "Tulsa Intl",
    cityCode: "TUL",
    cityName: "Tulsa",
    countryCode: "US",
    region: "OK",
  },
  {
    latitude: 36.8511,
    longitude: 10.22722,
    airportCode: "TUN",
    airportName: "Carthage",
    cityCode: "TUN",
    cityName: "Tunis",
    countryCode: "TN",
    region: "Tunisia",
  },
  {
    latitude: 32.1161,
    longitude: -110.94111,
    airportCode: "TUS",
    airportName: "Tucson Intl",
    cityCode: "TUS",
    cityName: "Tucson",
    countryCode: "US",
    region: "AZ",
  },
  {
    latitude: 44.7413,
    longitude: -85.58222,
    airportCode: "TVC",
    airportName: "Cherry Capital Airport",
    cityCode: "TVC",
    cityName: "Traverse City",
    countryCode: "US",
    region: "MI",
  },
  {
    latitude: 42.4816,
    longitude: -114.48777,
    airportCode: "TWF",
    airportName: "Twin Falls-Sun Valley Regl-Jo*",
    cityCode: "TWF",
    cityName: "Twin Falls",
    countryCode: "US",
    region: "ID",
  },
  {
    latitude: 37.7469,
    longitude: 112.62833,
    airportCode: "TYN",
    airportName: "Taiyuan Wusu Intl Arpt",
    cityCode: "TYN",
    cityName: "Taiyuan",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 35.8111,
    longitude: -83.99416,
    airportCode: "TYS",
    airportName: "Mc Ghee Tyson",
    cityCode: "TYS",
    cityName: "Knoxville",
    countryCode: "US",
    region: "TN",
  },
  {
    latitude: -18.8827,
    longitude: -48.22555,
    airportCode: "UDI",
    airportName: "Eduardo Gomes",
    cityCode: "UDI",
    cityName: "Uberlandia",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: -0.1411,
    longitude: -78.48833,
    airportCode: "UIO",
    airportName: "Mariscal Sucre",
    cityCode: "UIO",
    cityName: "Quito",
    countryCode: "EC",
    region: "Ecuador",
  },
  {
    latitude: 43.9072,
    longitude: 87.47416,
    airportCode: "URC",
    airportName: "Urumqi",
    cityCode: "URC",
    cityName: "Urumqi",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 13.7333,
    longitude: -60.9525,
    airportCode: "UVF",
    airportName: "Hewanorra Arpt",
    cityCode: "SLU",
    cityName: "St. Lucia",
    countryCode: "LC",
    region: "West Indies",
  },
  {
    latitude: 45.5052,
    longitude: 12.35194,
    airportCode: "VCE",
    airportName: "Marco Polo Arpt",
    cityCode: "VCE",
    cityName: "Venice",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 42.2316,
    longitude: -8.62666,
    airportCode: "VGO",
    airportName: "Vigo",
    cityCode: "VGO",
    cityName: "Vigo",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 48.1102,
    longitude: 16.57805,
    airportCode: "VIE",
    airportName: "Schwechat International",
    cityCode: "VIE",
    cityName: "Vienna",
    countryCode: "AT",
    region: "Austria",
  },
  {
    latitude: -20.258,
    longitude: -40.28638,
    airportCode: "VIX",
    airportName: "Eurico Sales",
    cityCode: "VIX",
    cityName: "Vitoria",
    countryCode: "BR",
    region: "Brazil",
  },
  {
    latitude: 39.4894,
    longitude: -0.48166,
    airportCode: "VLC",
    airportName: "Valencia",
    cityCode: "VLC",
    cityName: "Valencia",
    countryCode: "ES",
    region: "Spain",
  },
  {
    latitude: 30.7825,
    longitude: -83.27666,
    airportCode: "VLD",
    airportName: "Valdosta Regl",
    cityCode: "VLD",
    cityName: "Valdosta",
    countryCode: "US",
    region: "GA",
  },
  {
    latitude: 30.4866,
    longitude: -86.52,
    airportCode: "VPS",
    airportName: "Destin-Fort Walton Beach",
    cityCode: "VPS",
    cityName: "Destin-Fort Walton Beach",
    countryCode: "US",
    region: "FL",
  },
  {
    latitude: 45.3963,
    longitude: 10.88805,
    airportCode: "VRN",
    airportName: "Valerio Catullo",
    cityCode: "VRN",
    cityName: "Verona",
    countryCode: "IT",
    region: "Italy",
  },
  {
    latitude: 56.9291,
    longitude: 14.72805,
    airportCode: "VXO",
    airportName: "Vaxjo Smaland",
    cityCode: "VXO",
    cityName: "Vaxjo",
    countryCode: "SE",
    region: "Sweden",
  },
  {
    latitude: 52.1658,
    longitude: 20.96722,
    airportCode: "WAW",
    airportName: "Frederic Chopin",
    cityCode: "WAW",
    cityName: "Warsaw",
    countryCode: "PL",
    region: "Poland",
  },
  {
    latitude: 27.913,
    longitude: 120.85222,
    airportCode: "WNZ",
    airportName: "Wenzhou",
    cityCode: "WNZ",
    cityName: "Wenzhou",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 51.1027,
    longitude: 16.88583,
    airportCode: "WRO",
    airportName: "Copernicus Airport Wroclaw",
    cityCode: "WRO",
    cityName: "Wroclaw",
    countryCode: "PL",
    region: "Poland",
  },
  {
    latitude: 30.7838,
    longitude: 114.20805,
    airportCode: "WUH",
    airportName: "Wuhan Intl Arpt",
    cityCode: "WUH",
    cityName: "Wuhan",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 44.6883,
    longitude: -111.11777,
    airportCode: "WYS",
    airportName: "West Yellowstone Municipal Airport",
    cityCode: "WYS",
    cityName: "West Yellowstone",
    countryCode: "US",
    region: "MT",
  },
  {
    latitude: 34.4477,
    longitude: 108.75388,
    airportCode: "XIY",
    airportName: "Xianyang",
    cityCode: "XIY",
    cityName: "Xianyang - Xian",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 36.0047,
    longitude: -94.17,
    airportCode: "XNA",
    airportName: "Northwest Arkansas Reg Arpt",
    cityCode: "XNA",
    cityName: "Fayetteville-NW Reg",
    countryCode: "US",
    region: "AR",
  },
  {
    latitude: 36.5286,
    longitude: 102.04027,
    airportCode: "XNN",
    airportName: "Xining",
    cityCode: "XNN",
    cityName: "Xining",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 48.2616,
    longitude: -103.75083,
    airportCode: "XWA",
    airportName: "Williston Basin International Arpt",
    cityCode: "XWA",
    cityName: "Williston",
    countryCode: "US",
    region: "ND",
  },
  {
    latitude: 49.0522,
    longitude: -123.87027,
    airportCode: "YCD",
    airportName: "Nanaimo Arpt",
    cityCode: "YCD",
    cityName: "Nanaimo",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 53.3075,
    longitude: -113.58611,
    airportCode: "YEG",
    airportName: "Edmonton International",
    cityCode: "YEG",
    cityName: "Edmonton",
    countryCode: "CA",
    region: "AB",
  },
  {
    latitude: 45.8688,
    longitude: -66.53,
    airportCode: "YFC",
    airportName: "Fredericton Intl Arpt",
    cityCode: "YFC",
    cityName: "Fredericton",
    countryCode: "CA",
    region: "NB",
  },
  {
    latitude: 44.8869,
    longitude: -63.51277,
    airportCode: "YHZ",
    airportName: "Halifax Intl Arpt",
    cityCode: "YHZ",
    cityName: "Halifax",
    countryCode: "CA",
    region: "NS",
  },
  {
    latitude: 50.7022,
    longitude: -120.44444,
    airportCode: "YKA",
    airportName: "Kamloops Arpt",
    cityCode: "YKA",
    cityName: "Kamloops",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 49.9561,
    longitude: -119.37777,
    airportCode: "YLW",
    airportName: "Kelowna International Airport",
    cityCode: "YLW",
    cityName: "Kelowna",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 56.6533,
    longitude: -111.22194,
    airportCode: "YMM",
    airportName: "Fort McMurray Arpt",
    cityCode: "YMM",
    cityName: "Fort McMurray",
    countryCode: "CA",
    region: "AB",
  },
  {
    latitude: 37.4016,
    longitude: 121.37166,
    airportCode: "YNT",
    airportName: "Laishan Airport",
    cityCode: "YNT",
    cityName: "Yantai",
    countryCode: "CN",
    region: "China",
  },
  {
    latitude: 45.3225,
    longitude: -75.66916,
    airportCode: "YOW",
    airportName: "Ottawa Macdonald-Cartier International",
    cityCode: "YOW",
    cityName: "Ottawa",
    countryCode: "CA",
    region: "ON",
  },
  {
    latitude: 46.7911,
    longitude: -71.39333,
    airportCode: "YQB",
    airportName: "Quebec City Jean Lesage Intl Arpt",
    cityCode: "YQB",
    cityName: "Quebec",
    countryCode: "CA",
    region: "QC",
  },
  {
    latitude: 46.1069,
    longitude: -64.6925,
    airportCode: "YQM",
    airportName: "Greater Moncton International Airport",
    cityCode: "YQM",
    cityName: "Moncton",
    countryCode: "CA",
    region: "NB",
  },
  {
    latitude: 49.7108,
    longitude: -124.88666,
    airportCode: "YQQ",
    airportName: "Comox Valley Arpt",
    cityCode: "YQQ",
    cityName: "Comox",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 50.4294,
    longitude: -104.65722,
    airportCode: "YQR",
    airportName: "Regina",
    cityCode: "YQR",
    cityName: "Regina",
    countryCode: "CA",
    region: "SK",
  },
  {
    latitude: 48.3719,
    longitude: -89.32388,
    airportCode: "YQT",
    airportName: "Thunder Bay",
    cityCode: "YQT",
    cityName: "Thunder Bay",
    countryCode: "CA",
    region: "ON",
  },
  {
    latitude: 55.1797,
    longitude: -118.885,
    airportCode: "YQU",
    airportName: "Grande Prarie Arpt",
    cityCode: "YQU",
    cityName: "Grande Prarie",
    countryCode: "CA",
    region: "AB",
  },
  {
    latitude: 45.4705,
    longitude: -73.74083,
    airportCode: "YUL",
    airportName: "Pierre Elliott Trudeau Arpt",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryCode: "CA",
    region: "QC",
  },
  {
    latitude: 49.1938,
    longitude: -123.18444,
    airportCode: "YVR",
    airportName: "Vancouver Intl Arpt",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 49.91,
    longitude: -97.24,
    airportCode: "YWG",
    airportName: "Winnipeg",
    cityCode: "YWG",
    cityName: "Winnipeg",
    countryCode: "CA",
    region: "MB",
  },
  {
    latitude: 52.1708,
    longitude: -106.69972,
    airportCode: "YXE",
    airportName: "John G. Diefenbaker International",
    cityCode: "YXE",
    cityName: "Saskatoon",
    countryCode: "CA",
    region: "SK",
  },
  {
    latitude: 56.238,
    longitude: -120.74027,
    airportCode: "YXJ",
    airportName: "North Peace Regional Arpt",
    cityCode: "YYJ",
    cityName: "Fort St John",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 53.8819,
    longitude: -122.67583,
    airportCode: "YXS",
    airportName: "Prince George Arpt",
    cityCode: "YXS",
    cityName: "Prince George",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 43.0355,
    longitude: -81.15388,
    airportCode: "YXU",
    airportName: "London International",
    cityCode: "YXU",
    cityName: "London-Ontario",
    countryCode: "CA",
    region: "ON",
  },
  {
    latitude: 49.0252,
    longitude: -122.36055,
    airportCode: "YXX",
    airportName: "Abbotsford Intl Arpt",
    cityCode: "YXX",
    cityName: "Abbotsford",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 51.1138,
    longitude: -114.02027,
    airportCode: "YYC",
    airportName: "Calgary Intl Arpt",
    cityCode: "YYC",
    cityName: "Calgary",
    countryCode: "CA",
    region: "AB",
  },
  {
    latitude: 46.2891,
    longitude: -63.12555,
    airportCode: "YYG",
    airportName: "Charlottetown",
    cityCode: "YYG",
    cityName: "Charlottetown",
    countryCode: "CA",
    region: "PE",
  },
  {
    latitude: 48.6469,
    longitude: -123.42583,
    airportCode: "YYJ",
    airportName: "Victoria Intl Arpt",
    cityCode: "YYJ",
    cityName: "Victoria",
    countryCode: "CA",
    region: "BC",
  },
  {
    latitude: 47.6186,
    longitude: -52.75194,
    airportCode: "YYT",
    airportName: "St Johns",
    cityCode: "YYT",
    cityName: "St. Johns",
    countryCode: "CA",
    region: "NL",
  },
  {
    latitude: 43.6772,
    longitude: -79.63055,
    airportCode: "YYZ",
    airportName: "Lester B. Pearson Intl Arpt",
    cityCode: "YTO",
    cityName: "Toronto",
    countryCode: "CA",
    region: "ON",
  },
  {
    latitude: 62.4686,
    longitude: -114.44305,
    airportCode: "YZF",
    airportName: "Yellowknife Arpt",
    cityCode: "YZF",
    cityName: "Yellowknife",
    countryCode: "CA",
    region: "NT",
  },
  {
    latitude: 45.743,
    longitude: 16.06888,
    airportCode: "ZAG",
    airportName: "Pleso",
    cityCode: "ZAG",
    cityName: "Zagreb",
    countryCode: "HR",
    region: "Croatia",
  },
  {
    latitude: -6.2219,
    longitude: 39.225,
    airportCode: "ZNZ",
    airportName: "Kisauni Airport",
    cityCode: "ZNZ",
    cityName: "Zanzibar",
    countryCode: "TZ",
    region: "Tanzania",
  },
  {
    latitude: -45.0183,
    longitude: 168.74666,
    airportCode: "ZQN",
    airportName: "Queenstown Intl Arpt",
    cityCode: "ZQN",
    cityName: "Queenstown",
    countryCode: "NZ",
    region: "New Zealand",
  },
  {
    latitude: 47.4647,
    longitude: 8.54916,
    airportCode: "ZRH",
    airportName: "Zurich",
    cityCode: "ZRH",
    cityName: "Zurich",
    countryCode: "CH",
    region: "Switzerland",
  },
];

export default airports;
