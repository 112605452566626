/*********** TODO ********************************
 * Give focus to "Next" button when correctl-answered...
 * Allow "Next" button to use keyboard navigation "N" key to trigger...
 *
 *************************************************/

import {
  FormEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import Airport from "../interfaces/Airport";

interface DirectInputProps {
  airport: Airport;
  onClickNext: (numberOfAirports: number) => void;
}

const DirectInput: FunctionComponent<DirectInputProps> = ({
  airport,
  onClickNext,
}) => {
  const textField = useRef<HTMLInputElement>(null);
  const nextButton = useRef<HTMLButtonElement>(null);

  const [answer, setAnswer] = useState<string>("");
  const [hint, setHint] = useState<number>(0);
  const [showHintButton, setShowHintButton] = useState<boolean>(false);
  const answered = () => answer === airport.cityCode;
  const [incorrectCount, setIncorrectCount] = useState<number>(0);

  const clearAnswer = () => setAnswer("");
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value.toUpperCase();
    setAnswer(value);
    if (value.length === 3 && !didAnswerCorrectly()) {
      // Register a correct or an incorrect answer...
      setIncorrectCount(incorrectCount + 1);
      // After 5 seconds of no input, display a button to display a "hint".... (letters as "hint" are in green)
      // A correct answer should have the text as Green)
    }
  };

  useEffect(() => {
    if (textField.current) textField.current.focus();
    resetHintAndIncorrectCountAndAnswer();
    setTimeout(() => setShowHintButton(true), 3000);
  }, [airport]);

  const handleClickNext = () => {
    resetHintAndIncorrectCountAndAnswer();
    onClickNext(1); // Retrieve a new airport
    if (textField.current) textField.current.focus();
  };

  const resetHintAndIncorrectCountAndAnswer = () => {
    setHint(0);
    setIncorrectCount(0);
    clearAnswer(); // Clear answer
  };

  const revealOneLetter = () => {
    setHint(hint + 1);
    clearAnswer();
    document.getElementsByTagName("input")[0].focus();
  };

  const shouldShowHintButton = () =>
    (incorrectCount > 0 || showHintButton) && !didAnswerCorrectly();

  const didAnswerCorrectly = (value: string = answer) =>
    value.length === 3 && value === airport.cityCode;

  return (
    <div className="inputField w-full grid pt-20 text-center grid">
      <div>
        <h1>{`${airport.cityName}, ${airport.region}`}</h1>
        <input
          ref={textField}
          autoFocus={true}
          className={`font-mono mx-auto text-center text-4xl w-[4em] p-3 uppercase border ${
            (didAnswerCorrectly() &&
              answer.length === 3 &&
              "text-green-600 font-bold border-4 m-0 border-green-600 bg-green-200 outline-none") ||
            (answer.length === 3 &&
              "border-4 m-0 border-red-500 bg-red-200 ring-0 outline-none") ||
            "outline-black border-2 m-[2px]"
          }`}
          type="text"
          value={answer}
          onChange={handleChange}
          onClick={clearAnswer}
          disabled={didAnswerCorrectly()}
          maxLength={3}
          autoComplete="false"
        />
      </div>
      <div className="p-4 text-red-600">
        {incorrectCount !== 0 && !didAnswerCorrectly() && (
          <div>
            Incorrect guess #{incorrectCount > 0 && `${incorrectCount}`}
          </div>
        )}
        <button
          className={`underline text-blue-500 hover:text-red-500 hover:no-underline pb-4`}
          onClick={revealOneLetter}
        >
          Accept {hint === 0 ? "a" : "another"} hint?
        </button>
        {shouldShowHintButton() && (
          <>
            <pre className="font-mono text-4xl">
              {airport.cityCode.slice(0, hint)}
              {hint > 0 && [...Array(3 - hint)].map(() => "_")}
            </pre>
          </>
        )}
      </div>

      <button
        ref={nextButton}
        onClick={handleClickNext}
        className={`text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-xl focus:outline-none
        bg-blue-700 hover:bg-blue-800 hover:cursor-pointer mt-4 mx-4 p-4 ${
          didAnswerCorrectly() ? "" : "hidden"
        }`}
      >
        <u>N</u>ext
      </button>
    </div>
  );
};

export default DirectInput;
