/*********** TODO ********************************
 *
 *************************************************/

import { FunctionComponent, useState } from "react";
import Div100vh from "react-div-100vh";
import Airport from "../interfaces/Airport";

interface FlashcardsProps {
  airport: Airport;
  onClickFlashcard: (numberOfAirports: number) => void;
  flashcardState: boolean;
  codeOrCity: boolean;
}

const Flashcards: FunctionComponent<FlashcardsProps> = ({
  airport,
  onClickFlashcard,
  flashcardState,
  codeOrCity,
}) => {
  const [displayAnswer, setDisplayAnswer] = useState<boolean>(false);

  return (
    <Div100vh className="grid grid-rows-2 sm:grid-cols-2 h-full">
      <div
        className={`h-full w-full sm:h-screen bg-green-200 text-[5em] ${
          flashcardState ? "order-last" : ""
        } ${codeOrCity ? "text-[5em]" : "text-[2em]"}`}
      >
        <div className="h-full grid items-center text-center">
          <span>
            {codeOrCity
              ? airport.airportCode
              : `${airport.cityName}, ${airport.region}`}
          </span>
        </div>
      </div>
      <div
        className={`h-full sm:h-screen ${
          displayAnswer ? "bg-red-200" : "bg-black"
        } hover:cursor-pointer ${codeOrCity ? "text-[2em]" : "text-[5em]"}`}
        onPointerDown={(e) => {
          if (displayAnswer !== false) {
            setDisplayAnswer(false);
            onClickFlashcard(1);
          }
        }}
        onMouseOver={() => displayAnswer || setDisplayAnswer(!displayAnswer)}
      >
        <div className="h-full grid items-center text-center">
          <span>
            {codeOrCity
              ? `${airport.cityName}, ${airport.region}`
              : airport.airportCode}
          </span>
        </div>
      </div>
    </Div100vh>
  );
};

export default Flashcards;
