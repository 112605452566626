import { FunctionComponent, useEffect, useState } from "react";
import airports from "../data/airports";
import Airport from "../interfaces/Airport";
import DirectInput from "./DirectInput";
import Flashcards from "./Flashcards";
import MultipleChoice from "./MultipleChoice";
// import Matching from "./Matching";

interface GameProps {}

const Game: FunctionComponent<GameProps> = () => {
  const getRandom = (arr: Array<Airport>, numberOfAirports: number) => {
    var result = new Array(numberOfAirports),
      len = arr.length,
      taken = new Array(len);
    if (numberOfAirports > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (numberOfAirports--) {
      var x = Math.floor(Math.random() * len);
      result[numberOfAirports] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result.map((obj) => ({ ...obj }));
  };

  const [gameState, setGameState] = useState<string>(
    JSON.parse(localStorage.getItem("game-state") || '"Flashcards"')
  );
  const [airportChoices, setAirportChoices] = useState<Airport[]>(
    getRandom(airports, 5)
  );
  const [flashcardState, setFlashcardState] = useState<boolean>(true);
  const [codeOrCity, setCodeOrCity] = useState<boolean>(Math.random() < 0.5);

  const handleNewQuestion = (numberOfAirports: number) => {
    setFlashcardState(!flashcardState);
    chooseNewAirports(numberOfAirports);
    setCodeOrCity(Math.random() < 0.5);
  };

  useEffect(() => {
    localStorage.setItem("game-state", JSON.stringify(gameState));
  }, [gameState]);

  const chooseNewAirports = (numberOfAirports: number = 5) =>
    setAirportChoices(getRandom(airports, numberOfAirports));

  useEffect(() => {
    chooseNewAirports();
  }, [gameState]);

  return (
    <>
      <div className="absolute w-full bg-white overflow-x-auto whitespace-nowrap">
        {["Flashcards", "Multiple Choice", "Direct Input", "Matching"].map(
          (value, index) => {
            return (
              <button
                key={index}
                className={`px-4 py-2 ${gameState === value && "bg-green-200"}`}
                onClick={() => setGameState(value)}
              >
                {value}
              </button>
            );
          }
        )}
      </div>
      {gameState === "Flashcards" && (
        <Flashcards
          onClickFlashcard={handleNewQuestion}
          airport={airportChoices[0]}
          flashcardState={flashcardState}
          codeOrCity={codeOrCity}
        />
      )}
      {gameState === "Multiple Choice" && (
        <MultipleChoice
          airports={airportChoices}
          codeOrCity={codeOrCity}
          onClickNext={handleNewQuestion}
        />
      )}
      {/*
      {gameState === "Matching" && (
        <Matching airports={getRandom(airports, 5)} />
      )}
      */}
      {gameState === "Direct Input" && (
        <DirectInput
          airport={getRandom(airports, 1)[0]}
          onClickNext={handleNewQuestion}
        />
      )}
    </>
  );
};

export default Game;
